import { Container } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import {
  MessageBanner,
  MessageBannerType,
} from "@amzn/stencil-react-components/message-banner";
import { Text } from "@amzn/stencil-react-components/text";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import { Logger } from "@/apis/Logger";
import { Metrics } from "@/apis/Metrics";
import { resetStepCache } from "@/helpers/CachedStepProgress";

export interface ErrorBoundaryProps {
  children: JSX.Element;
}

export const IT_SUPPORT_LINK = "https://firstaid.amazon.com";

/**
 * Implements an error boundary pattern in which it captures errors bubbling up from children and reports them via Logger.
 * To use, wrap the child component such as `<ErrorBoundary><Child /></ErrorBoundary>`
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  state = { hasError: false };

  private metricsPublisherHandle =
    Metrics.publisherInstance.newChildActionPublisherForMethod("ErrorBoundary");

  /**
   * Sets the state given an error. This is useful for server side rendering but is included here to cover all cases.
   */
  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  /**
   * Method called when a child component errors on render.
   * @param error Error bubbled up from child.
   * @param errorInfo Error stack information.
   */
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ hasError: true });
    void Logger.fatal("Error boundary caught while rendering page", error, {
      componentStack: errorInfo.componentStack,
    });

    this.metricsPublisherHandle.publishCounterMonitor(
      "RootAppErrorBoundaryCaught",
      1
    );

    // In case an issue with the cache is causing the app to crash, clear the persistent cache.
    resetStepCache();
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <Container paddingTop="1.875rem" dataTestId="failedToRenderContainer">
          <MessageBanner type={MessageBannerType.Warning}>
            <Text>
              {/* Use an external help link since the app has crashed so the widget flyout isn't going to be available. */}
              <FormattedMessage
                id="failedToRender"
                values={{
                  link: (
                    <Link
                      href={IT_SUPPORT_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {IT_SUPPORT_LINK}
                    </Link>
                  ),
                }}
              />
            </Text>
          </MessageBanner>
        </Container>
      );
    }

    return this.props.children;
  }
}
