import "./styles/common.scss";

import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import { render } from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { getRetryDelayMs, shouldRetry } from "@/helpers/react-query-helper";

import App from "./components/App";
import { StateProvider } from "./stores";

const TEN_MINS_IN_MS = 600_000;

// Create a React-Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: TEN_MINS_IN_MS,
      cacheTime: TEN_MINS_IN_MS,
      // Avoid query data too frequently since the data is unlikely to changed within a short period of time.
      refetchOnWindowFocus: false,

      /**
       * Only retry on Axios errors with a transient error code if the failure count is within the retry limit.
       * @param failureCount The number of failure attempts
       * @param err An Error instance
       */
      retry: (failureCount, err) => shouldRetry(failureCount, err),

      retryDelay: (retryCount) => getRetryDelayMs(retryCount),
    },
  },
});
const BROWSER_LOCALE = navigator.language;
dayjs.extend(utc);
dayjs.extend(timezone);

render(
  <QueryClientProvider client={queryClient}>
    {/* This will be only running in the development env. Useful for debugging purposes */}
    <ReactQueryDevtools initialIsOpen={false} />
    <StateProvider>
      <App currentLocale={BROWSER_LOCALE} />
    </StateProvider>
  </QueryClientProvider>,
  document.getElementById("app")
);
