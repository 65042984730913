import {
  IconAlertTriangleFill,
  IconSize,
} from "@amzn/stencil-react-components/icons";
import { Row, View } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";

/**
 * An error message banner that will be used to notify about the issues occurred during the onboarding process.
 */
export default function ErrorBanner(props: {
  children: JSX.Element;
}): JSX.Element {
  return (
    <View backgroundColor="yellow05" padding="S300" border="2px solid yellow70">
      <Row gridGap="S200">
        <IconAlertTriangleFill
          size={IconSize.ExtraSmall}
          color="yellow70"
          margin={{ top: "0.4rem" }}
          dataTestId="iconAlertTriangleFill"
        />
        <Text fontWeight="light" dataTestId="idCheckErrorMsg">
          {props.children}
        </Text>
      </Row>
    </View>
  );
}
