import { UserClientApp } from "@amzn/client-workbench-api-model";

import { Metrics } from "@/apis/Metrics";

export const RETRY_LIMIT = 3;
export const CLIENT_APP: UserClientApp = "DayOneOnboardingWizard";

export const TRANSIENT_ERROR_CODES = [
  408, // Request Timeout
  409, // Conflict
  423, // Locked
  429, // Limit Exceeded
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Endpoint Request Timed-out
];

export enum BLOCKING_ERROR_TYPE {
  /** Early access error before Day1 */
  BeforeDay1 = "BeforeDay1",

  /** User progress errors or late access error after Day1 */
  NotDay1 = "NotDay1",

  /** No start date assigned to the NH. */
  NoStartDate = "NoStartDate",

  /** Alias is not found for the NH */
  NoAlias = "NoAlias",

  /** The default generic Day1 blocking error */
  Generic = "Generic",
}

export const API_METRICS_HANDLE =
  Metrics.publisherInstance.newChildActionPublisherForMethod("API");
