import { trackStructEvent } from "@snowplow/browser-tracker";

import { Day1EventAction, Day1EventCategory } from "@/types/snowplow-events";

/**
 * Method reference used to give `window.add/removeEventListener('...', fn)` a handle for `fn`.
 */
export const appOnCloseFunction = function (): void {
  // Check if we have a user ID in snowplow because that means this is a real user not a bot or something loading/unloading the site.
  trackStructEvent({
    category: Day1EventCategory.PageLevel,
    action: Day1EventAction.WizardUnload,
  });
};
