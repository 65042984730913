import { useQuery, UseQueryResult } from "react-query";

import { notifyUser } from "@/apis/UserNotificationHelper";
import { getRetryDelayMs, shouldRetry } from "@/helpers/react-query-helper";

/**
 * Custom hook to notify users about the completion of the Day 1 Wizard.
 */
export const useUserNotify = (): UseQueryResult<void> => {
  return useQuery("notifyUser", notifyUser, {
    retry: (failureCount, err) => shouldRetry(failureCount, err),
    retryDelay: (retryCount) => getRetryDelayMs(retryCount),
  });
};
