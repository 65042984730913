export const CACHE_KEY_STEP = "wizardProgressKey";

/**
 * Gets the current step from localStorage cache, returning either step number 0 if nothing is cached, or the value that is cached (0 based).
 */
export function getCurrentStepNumFromCache() {
  const DEFAULT_STEP_NUM = 0;
  try {
    const valueFromCache = localStorage.getItem(CACHE_KEY_STEP);
    // Double check we can convert the value to a number, then convert it and set the current step index to that value.
    if (!isNaN(Number(valueFromCache))) {
      return Number(valueFromCache);
    } else {
      return DEFAULT_STEP_NUM;
    }
  } catch {
    // Getting the value from cache or converting the value failed for some reason.
    console.error(
      "Can't get previous step from progress cache, resetting step progress."
    );
    return DEFAULT_STEP_NUM;
  }
}

/**
 * Resets step cache by removing the key from the cache.
 */
export function resetStepCache() {
  try {
    localStorage.removeItem(CACHE_KEY_STEP);
  } catch {
    // Catch and try not to make things worse if there was a problem.
    console.error("Unable to reset step cache");
  }
}

/**
 * Sets the current step number in cache.
 * @param stepNumber Step number to set, 0 based. If you use `state.currentStepNum`, you need to subtract 1 since that number is 1 based.
 */
export function setStepCache(stepNumber: number) {
  try {
    localStorage.setItem(CACHE_KEY_STEP, String(stepNumber));
  } catch {
    // Catch and try not to make things worse if there was a problem.
    console.error("Unable to set step in cache");
  }
}
