import { StructuredEvent } from "@snowplow/tracker-core";

import { RefPageValue } from "@/components/GetSupport/ChatWithITSupport/types";
import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventLabel,
} from "@/types/snowplow-events";

const BLOCKING_ERROR_BASE_STRUCTURED_EVENT: Readonly<StructuredEvent> = {
  action: Day1EventAction.ChatWithSupport,
  category: Day1EventCategory.BlockingError,
};
type ChatSupportContextReasonsFRIV =
  | "ON_FULL_RIV_TASK_SELECTION"
  | "ON_FULL_RIV_PRIMARY_ID_STEP"
  | "ON_FULL_RIV_SECONDARY_ID_STEP"
  | "ON_FULL_RIV_CHALLENGE_STEP"
  | "ON_FULL_RIV_WAIT_STEP"
  | "ON_FULL_RIV_RESULT_SUCCESS"
  | "ON_FULL_RIV_RESULT_FAIL";
type ChatSupportContextReasonsLRIV =
  | "ON_LIGHT_RIV_CHALLENGE_STEP"
  | "ON_LIGHT_RIV_WAIT_STEP"
  | "ON_LIGHT_RIV_RESULT_SUCCESS"
  | "ON_LIGHT_RIV_RESULT_FAIL";
type ChatSupportContextReasonsSteps =
  | "ON_USERNAME_STEP"
  | "ON_MIDWAY_STEP"
  | "ON_MIDWAY_PIN_SENT"
  | "ON_MIDWAY_ERROR_100"
  | "ON_MIDWAY_ERROR_200"
  | "ON_MIDWAY_ERROR_999"
  | "ON_CONNECT_TO_VPN_STEP"
  | "ON_CREATE_PASSWORD_STEP"
  | "ON_PRECACHE_STEP"
  | "ON_WIZARD_COMPLETED_STEP";
type ChatSupportContextReasonsBlockingError =
  | "BLOCKED_IMMEDIATELY_AFTER_PASSPORT_LOGIN"
  | "BLOCKED_BEFORE_DAY_1"
  | "BLOCKED_NOT_DAY_1"
  | "BLOCKED_NO_ALIAS"
  | "BLOCKED_NO_START_DATE"
  | "BLOCKED_GENERIC";

/**
 * @member `ON_IVV_CONSENT_TO_BIOMETRICS` - the new hire failed manual ID verification and is eligible for automated ID verification but does not want to consent to the use of biometrics
 */
type ChatSupportContextReasonsIVV =
  | "ON_IVV_CONSENT_TO_BIOMETRICS"
  | "ON_IVV_SUCCESS"
  | "ON_IVV_PENDING"
  | "ON_IVV_EXPIRED"
  | "ON_IVV_REJECTED"
  | "ON_IVV_REJECTED_NO_FALL_BACK";
/**
 * All non-directed chat support reasons as a type
 */
export type ChatSupportContextReason =
  | ChatSupportContextReasonsFRIV
  | ChatSupportContextReasonsLRIV
  | ChatSupportContextReasonsSteps
  | ChatSupportContextReasonsBlockingError
  | ChatSupportContextReasonsIVV;
export const ChatSupportContextStepToRefPage: Record<
  ChatSupportContextReason,
  RefPageValue
> = {
  BLOCKED_BEFORE_DAY_1: "ezo-blocking-error-not-day-1",
  BLOCKED_NOT_DAY_1: "ezo-blocking-error-not-day-1",
  BLOCKED_NO_START_DATE: "ezo-blocking-error-100",
  BLOCKED_NO_ALIAS: "ezo-blocking-error-200",
  BLOCKED_IMMEDIATELY_AFTER_PASSPORT_LOGIN: "ezo-blocking-error-300",
  BLOCKED_GENERIC: "ezo-blocking-error-400",
  ON_FULL_RIV_TASK_SELECTION: "ezo-identity-check-full",
  ON_FULL_RIV_PRIMARY_ID_STEP: "ezo-identity-check-full-pri",
  ON_FULL_RIV_SECONDARY_ID_STEP: "ezo-identity-check-full-sec",
  ON_FULL_RIV_CHALLENGE_STEP: "ezo-identity-check-full-challenge",
  ON_FULL_RIV_WAIT_STEP: "ezo-identity-check-full-wait",
  ON_FULL_RIV_RESULT_SUCCESS: "ezo-identity-check-full-pass",
  ON_FULL_RIV_RESULT_FAIL: "ezo-identity-check-full-fail",
  ON_LIGHT_RIV_CHALLENGE_STEP: "ezo-identity-check-lightweight",
  ON_LIGHT_RIV_WAIT_STEP: "ezo-identity-check-lightweight-wait",
  ON_LIGHT_RIV_RESULT_SUCCESS: "ezo-identity-check-lightweight-pass",
  ON_LIGHT_RIV_RESULT_FAIL: "ezo-identity-check-lightweight-fail",
  ON_IVV_CONSENT_TO_BIOMETRICS: "ezo-biometric-preference-contact-card",
  ON_USERNAME_STEP: "ezo-amazon-username",
  ON_MIDWAY_STEP: "ezo-midway-register-sec-key",
  ON_MIDWAY_PIN_SENT: "ezo-midway-register-sec-key-after-temp-pin",
  ON_CONNECT_TO_VPN_STEP: "ezo-connect-to-vpn",
  ON_CREATE_PASSWORD_STEP: "ezo-create-amazon-password",
  ON_PRECACHE_STEP: "ezo-install-precache",
  ON_WIZARD_COMPLETED_STEP: "ezo-complete",
  ON_MIDWAY_ERROR_100: "ezo-mw-error-100",
  ON_MIDWAY_ERROR_200: "ezo-mw-error-200",
  ON_MIDWAY_ERROR_999: "ezo-mw-error-999",
  ON_IVV_SUCCESS: "ezo-identity-check-ivv-success",
  ON_IVV_REJECTED: "ezo-identity-check-ivv-rejected",
  ON_IVV_EXPIRED: "ezo-identity-check-ivv-expired",
  ON_IVV_PENDING: "ezo-identity-check-ivv-pending",
  ON_IVV_REJECTED_NO_FALL_BACK:
    "ezo-identity-check-automated-failed-no-fallback",
};
/**
 * Reason to metrics properties map for chat support context.
 */
export const CHAT_SUPPORT_CONTEXTS_METRICS_MAP: Readonly<
  Record<ChatSupportContextReason, StructuredEvent>
> = {
  ON_IVV_REJECTED_NO_FALL_BACK: {
    category: Day1EventCategory.AutomatedRivOutcome,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultFail,
  },
  ON_MIDWAY_ERROR_100: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.MIDWAY_ERROR_100,
  },

  ON_MIDWAY_ERROR_200: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.MIDWAY_ERROR_200,
  },

  ON_MIDWAY_ERROR_999: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.MIDWAY_ERROR_999,
  },

  // When we block the user from proceeding further immediately after they successfully authenticate with PPv2.
  BLOCKED_IMMEDIATELY_AFTER_PASSPORT_LOGIN:
    BLOCKING_ERROR_BASE_STRUCTURED_EVENT,

  // When we block the user from proceeding further immediately when they are not allowed to start their Day1 onboarding.
  BLOCKED_NOT_DAY_1: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.NotDay1,
  },

  // When we block the user from proceeding further immediately when they try to access before their actual start date.
  BLOCKED_BEFORE_DAY_1: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.NotDay1,
  },

  // When we block the user from proceeding further immediately when they don't have a valid start date.
  BLOCKED_NO_START_DATE: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.NoStartDate,
  },

  // When we block the user from proceeding further immediately when they don't have an alias.
  BLOCKED_NO_ALIAS: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.AccountIssue,
  },

  // The default Day1 blocking error.
  BLOCKED_GENERIC: {
    ...BLOCKING_ERROR_BASE_STRUCTURED_EVENT,
    label: Day1EventLabel.GenericError,
  },

  // When the user is on the task selection step of full RIV.
  ON_FULL_RIV_TASK_SELECTION: {
    category: Day1EventCategory.IDCheckFull,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is on the primary ID step of full RIV.
  ON_FULL_RIV_PRIMARY_ID_STEP: {
    category: Day1EventCategory.IDCheckFullPri,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is on the secondary ID step of full RIV.
  ON_FULL_RIV_SECONDARY_ID_STEP: {
    category: Day1EventCategory.IDCheckFullSec,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is on the challenge action step of full RIV.
  ON_FULL_RIV_CHALLENGE_STEP: {
    category: Day1EventCategory.IDCheckFullChallenge,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is on the wait step of full RIV, waiting for the results.
  ON_FULL_RIV_WAIT_STEP: {
    category: Day1EventCategory.IDCheckFullWait,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.WaitForResult,
  },

  // When the user is on the challenge step (the only step) of lightweight RIV.
  ON_LIGHT_RIV_CHALLENGE_STEP: {
    category: Day1EventCategory.IDCheckLight,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is on the wait step of lightweight RIV, waiting for the results
  ON_LIGHT_RIV_WAIT_STEP: {
    category: Day1EventCategory.IDCheckLightWait,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.WaitForResult,
  },

  // When the user is on the wait step of full RIV, and they are informed that they have passed verification.
  ON_FULL_RIV_RESULT_SUCCESS: {
    category: Day1EventCategory.IDCheckFullWait,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultPass,
  },

  // When the user is on the wait step of lightweight RIV, and they are informed that they have passed verification.
  ON_LIGHT_RIV_RESULT_SUCCESS: {
    category: Day1EventCategory.IDCheckLightWait,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultPass,
  },

  // When the user is on the wait step of full RIV, and they are informed that they have failed verification.
  ON_FULL_RIV_RESULT_FAIL: {
    category: Day1EventCategory.IDCheckFullWait,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultFail,
  },

  // When the user is on the wait step of lightweight RIV, and they are informed that they have failed verification.
  ON_LIGHT_RIV_RESULT_FAIL: {
    category: Day1EventCategory.IDCheckLightWait,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultFail,
  },

  ON_IVV_CONSENT_TO_BIOMETRICS: {
    category: Day1EventCategory.IDVPrefSelect,
    action: Day1EventAction.IDVSelectContactSupport,
  },

  ON_IVV_EXPIRED: {
    category: Day1EventCategory.AutomatedRivOutcome,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultExpiry,
  },
  ON_IVV_REJECTED: {
    category: Day1EventCategory.AutomatedRivOutcome,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultFail,
  },
  ON_IVV_PENDING: {
    category: Day1EventCategory.AutomatedRivOutcome,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.WaitForResult,
  },
  ON_IVV_SUCCESS: {
    category: Day1EventCategory.AutomatedRivOutcome,
    action: Day1EventAction.ChatWithSupport,
    label: Day1EventLabel.ResultPass,
  },

  // When the user is shown their username.
  ON_USERNAME_STEP: {
    category: Day1EventCategory.AmazonUsername,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is shown the Midway step.
  ON_MIDWAY_STEP: {
    category: Day1EventCategory.MidwayRegisterSecKey,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is on the Midway step and a temp PIN was sent.
  ON_MIDWAY_PIN_SENT: {
    category: Day1EventCategory.MidwayRegisterSecKey,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is shown the Connect to VPN step.
  ON_CONNECT_TO_VPN_STEP: {
    category: Day1EventCategory.ConnectToVpn,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is shown the Create Amazon Password step.
  ON_CREATE_PASSWORD_STEP: {
    category: Day1EventCategory.CreateAmazonPassword,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is shown the Install Pre-Cache step.
  ON_PRECACHE_STEP: {
    category: Day1EventCategory.InstallPrecache,
    action: Day1EventAction.ChatWithSupport,
  },

  // When the user is shown the complete success message.
  ON_WIZARD_COMPLETED_STEP: {
    category: Day1EventCategory.Complete,
    action: Day1EventAction.ChatWithSupport,
  },
};
