import { trackStructEvent } from "@snowplow/browser-tracker";
import { StructuredEvent } from "@snowplow/tracker-core";

/**
 * Helper method to cache a metrics event once persistently in indexedDB.
 * This is useful for when we want a metric to be tracked only once across browser sessions, even after refreshing the page.
 * TODO: potentially switch to using localForage in the future --> https://issues.amazon.com/issues/NHE-1522
 */
export const trackStructEventOnceWithPersistence = ({
  category,
  action,
  label,
  property,
  value,
}: StructuredEvent): void => {
  const event = { action, category, label, property, value };
  const metricKey = `${category}-${action}${label ? "-" + label : ""}`;
  if (!localStorage.getItem(metricKey)) {
    trackStructEvent(event);
    localStorage.setItem(metricKey, "1");
  }
};
