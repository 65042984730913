import { API } from "@aws-amplify/api";

import { API_METRICS_HANDLE } from "@/apis/constants";
import { Logger } from "@/apis/Logger";
import {
  publishQueryLatencyMetrics,
  publishQueryResultMetrics,
} from "@/apis/Metrics";

import { AMPLIFY_DEFAULT_API_NAME } from "../amplifyConfig";

export const FETCH_AMAZON_ALIAS_QUERY_KEY = "FetchUserAlias";

// The error message returned when the caller is not allowed to use this API yet.
export const TOO_EARLY_ERROR_MSG = "Too early for access";

/**
 * Return the Amazon alias that is assigned to the NH.
 * TODO: retry logic
 */
export const fetchAmazonAlias = async (): Promise<string> => {
  const startTime = new Date().getTime();
  try {
    const alias = (await API.get(
      AMPLIFY_DEFAULT_API_NAME,
      "/user/alias",
      {}
    )) as Promise<string>;

    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: FETCH_AMAZON_ALIAS_QUERY_KEY,
      numSuccess: 1,
      numFailures: 0,
    });

    return alias;
  } catch (error) {
    void Logger.errorErrorLike(
      `${FETCH_AMAZON_ALIAS_QUERY_KEY} failed API call.`,
      error
    );
    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: FETCH_AMAZON_ALIAS_QUERY_KEY,
      numSuccess: 0,
      numFailures: 1,
    });
    return Promise.reject(error);
  } finally {
    const elapsedTime = new Date().getTime() - startTime;
    publishQueryLatencyMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: FETCH_AMAZON_ALIAS_QUERY_KEY,
      elapsedTime: elapsedTime,
    });
  }
};
