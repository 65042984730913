import { UserNotifyRequestSchema } from "@amzn/client-workbench-api-model";
import { API } from "@aws-amplify/api";

import { API_METRICS_HANDLE } from "@/apis/constants";
import { Logger } from "@/apis/Logger";
import {
  publishQueryLatencyMetrics,
  publishQueryResultMetrics,
} from "@/apis/Metrics";

import { AMPLIFY_DEFAULT_API_NAME } from "../amplifyConfig";

export const USER_NOTIFICATION_METRICS_NAME = "UserNotifyAPICall";

/**
 * Inform the NH about all pre-login onboarding steps have been completed and the instructions for continuing the
 * onboarding steps for post-login.
 */
export const notifyUser = async (): Promise<void> => {
  const startTime = performance.now();
  const body: UserNotifyRequestSchema = {
    notificationReason: "DAY_1_WIZARD_COMPLETED",
  };

  try {
    await API.post(AMPLIFY_DEFAULT_API_NAME, "/user/notify", {
      body: body,
    });
    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: USER_NOTIFICATION_METRICS_NAME,
      numFailures: 0,
      numSuccess: 1,
    });
  } catch (err) {
    void Logger.errorErrorLike(
      `${USER_NOTIFICATION_METRICS_NAME} failed API call.`,
      err
    );
    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: USER_NOTIFICATION_METRICS_NAME,
      numFailures: 1,
      numSuccess: 0,
    });
    return Promise.reject(err);
  } finally {
    publishQueryLatencyMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: USER_NOTIFICATION_METRICS_NAME,
      elapsedTime: performance.now() - startTime,
    });
  }
};
