import { trackStructEvent } from "@snowplow/browser-tracker";

import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventLabel,
} from "@/types/snowplow-events";

import { MetricsEvent } from "./snowplow";

type EventKey =
  | `${Day1EventCategory}#${Day1EventAction}`
  | `${Day1EventCategory}#${Day1EventAction}#${Day1EventLabel}`;

const REGISTERED_EVENT_KEYS = new Set<EventKey>();

/**
 * Wrapper on `trackStructEvent` to avoid registering duplicate event metrics
 * for events per app session (e.g. refreshing the page will still register
 * the event).
 */
export const trackStructEventOnceAppSession = (event: MetricsEvent): void => {
  const eventKey: EventKey = event.label
    ? (`${event.category}#${event.action}#${event.label}` as const)
    : (`${event.category}#${event.action}` as const);

  if (REGISTERED_EVENT_KEYS.has(eventKey)) return;

  REGISTERED_EVENT_KEYS.add(eventKey);
  trackStructEvent(event);
};
