import { VerificationRequestSchema } from "@amzn/client-workbench-api-model";
import { ActivatedRegions } from "src/amplifyStageConfig";

import { VerificationStatus } from "@/apis/IdentityCheckHelper";
import {
  DAY1_ONBOARDING_STEPS,
  Step,
  VideoRecordingSubmission,
} from "@/components/constants";
import { ChatSupportContextReason } from "@/components/GetSupport/ChatWithITSupport/constants";
import {
  RefLinkValue,
  RefPageValue,
} from "@/components/GetSupport/ChatWithITSupport/types";
import { getCurrentStepNumFromCache } from "@/helpers/CachedStepProgress";

export type WizardPathType = "IVV" | "ManualRIV" | undefined;

/**
 * The app state interface
 */
export interface AppState {
  /**
   * The current step number that NH is working on. 1 based index.
   */
  currentStepNum: number;

  /**
   * The current step that NH is working on.
   */
  currentStep: Step;

  /**
   * All on-boarding steps that NHs will need to complete.
   */
  steps: Step[];

  /**
   * The network connection status in between NH's laptop and the internet.
   */
  isNetworkConnected: boolean;

  /**
   * The Amazon username that is assigned to the NH.
   */
  amazonUsername?: string;

  /**
   * Number of the required documents needed to verify NH's ID.
   */
  requiredDocsNum: number;

  /**
   * All documents that NHs have submitted so far. Only video recordings are required from NHs for the Day1 wizard right
   * now.
   */
  documents: VideoRecordingSubmission[];

  /**
   * The verification status of the documents that NHs are required to submit.
   */
  docsVerificationStatus: VerificationStatus;

  /**
   * The selected primary Id for the Identity verification step
   */
  selectedPrimaryIdType?: VerificationRequestSchema["primaryIdFormType"];

  /**
   * If Amplify is configured yet.
   */
  amplifyConfigured: boolean;

  /**
   *  The chat support context to use.
   */
  nonDirectedChatSupportContext?: ChatSupportContextReason;

  /**
   * The active region that controls which regional endpoint to use
   * for API calls and which Cognito auth config to use
   */
  activeRegion?: ActivatedRegions;

  wizardPath: {
    /**
     * The previous wizard path type that users are routed to.
     */
    previous: WizardPathType;

    /**
     * The wizard path type that users are currently on.
     */
    current: WizardPathType;
  };

  /**
   * If the chat support flyout is visible
   */
  chatSupportFlyoutVisible: boolean;

  /**
   * If the get support flyout is visible
   */
  getSupportFlyoutVisible: boolean;

  /**
   * The chat support ref page.
   */
  chatSupportRefPage?: RefPageValue;

  /**
   * The chat support ref link value
   */
  chatSupportRefLink?: RefLinkValue;
}

/**
 * The Action interface for updating the app state in the AppProvider.
 */
export interface Action {
  /**
   * The type of the action
   */
  type: ActionType;

  /**
   * The step ID of the step which sends the action
   */
  stepId?: string;

  /**
   * The value of the network connection status that will be used to update the isNetworkConnected value in the app
   * state
   */
  isNetworkConnected?: boolean;

  /**
   * Should the next button be enabled.
   */
  enableNext?: boolean;

  /**
   * The username that is assigned to the NH.
   */
  amazonUsername?: string;

  /**
   * The document submitted by NHs. We only require video recordings from NHs for the Day1 on-boarding wizard.
   */
  document?: VideoRecordingSubmission;

  /**
   * The required documents number needed to be submitted by NHs.
   */
  requiredDocsNum?: number;

  /**
   * Should the next button be hidden.
   */
  hideNext?: boolean;

  /**
   * The verification status of the submitted documents in the Identity Check step.
   */
  docsVerificationStatus?: VerificationStatus;

  /**
   * The selected primary ID for verification by NH.
   */
  primaryIdType?: VerificationRequestSchema["primaryIdFormType"];

  /**
   * If amplify is configured yet
   */
  amplifyConfigured?: boolean;

  /**
   * The i18n id that will be used to map into the tooltip text shown on a disabled button.
   */
  disabledBtnToolTipI18nId?: string;

  /**
   * Sets the non directed chat support context. Used by the header chat support to set click metrics based on this property.
   */
  nonDirectedChatSupportContext?: ChatSupportContextReason;

  /**
   * The active region that controls which regional endpoint to use
   * for API calls and which Cognito auth config to use
   */
  activeRegion?: ActivatedRegions;

  wizardPath?: WizardPathType;

  /**
   * If the chat support flyout is visible
   */
  chatSupportFlyoutVisible?: boolean;

  /**
   * If the get support flyout is visible
   */
  getSupportFlyoutVisible?: boolean;

  /**
   * The ref page to set.
   */
  chatSupportRefPage?: RefPageValue;

  /**
   * The chat support link context.
   */
  chatSupportRefLink?: RefLinkValue;
}

/**
 * Action enums that will be used to update the app state in the AppProvider
 */
export enum ActionType {
  /**
   * The action to reset to the initial state
   */
  RESET = "reset",

  /**
   * The action to enable/disable the next step of the target step
   */
  SET_NEXT_BTN_STATUS = "setNextBtnStatus",

  /**
   * The action to mark the target step as completed and increment the current step number by 1
   */
  NEXT_STEP = "nextStep",

  /**
   * The action to update the status of network connection of the NHs laptops
   */
  UPDATE_NETWORK = "updateNetwork",

  /**
   * The action to set the Amazon username assigned to the NH
   */
  SET_USERNAME = "setAmazonUsername",

  /**
   * The action to append the document to the existing documents list. NHs are required to upload video recordings only
   * for day1 on-boarding.
   */
  ADD_DOCUMENT = "addDocument",

  /**
   * The action to remove the last document to the existing documents list. NHs are required to upload video recordings
   * only for day1 on-boarding.
   */
  POP_DOCUMENT = "popDocument",

  /**
   * The action to show/hide the next button on each on-boarding step.
   */
  SET_HIDE_NEXT = "setHideNext",

  /**
   * The action to set the number of required documents that NHs need to submit to verify their IDs.
   */
  SET_REQUIRED_DOCS_NUM = "setRequiredDocsNum",

  /**
   * The action to set the verification status of the NH's submitted documents.
   */
  SET_DOCS_VERIFICATION_STATUS = "setDocsVerificationStatus",

  /**
   * Mark the last recorded document as submitted. It is used for indicating that NHs have clicked the submit button
   * but not the actual upload since we don't do the actual upload until we have collected all required documents.
   */
  SET_LAST_DOC_SUBMIT_CLICKED = "setLastDocSubmitClicked",

  /**
   * The action to set the primary ID for ID verification.
   */
  SET_PRIMARY_ID_TYPE = "setPrimaryIdType",

  /**
   * If Amplify is configured yet. It should be configured on start however if components are loaded which immediately try to perform API calls, it will fail.
   */
  SET_AMPLIFY_CONFIGURED = "setAmplifyConfigured",

  /**
   * Set non directed chat support context.
   */
  SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT = "setNonDirectedChatSupportContext",

  /**
   * Set the API active region.
   */
  SET_API_ACTIVE_REGION = "setApiActiveRegion",

  SET_WIZARD_PATH = "setWizardPath",

  /**
   * Sets the chat support flyout to visible/open.
   */
  SET_CHAT_SUPPORT_FLYOUT_VISIBLE = "setChatSupportFlyoutVisible",

  /**
   * Sets the get support flyout to visible.
   */
  SET_GET_SUPPORT_FLYOUT_VISIBLE = "setGetSupportFlyoutVisible",

  /**
   * Sets the chat support ref page
   */
  SET_CHAT_SUPPORT_REF_PAGE = "setChatSupportRefPage",

  /**
   * Sets the chat support link string. This indicates where the origin of the request was, inline IT support help or clicking Get Support
   */
  SET_CHAT_SUPPORT_REF_LINK = "setChatSupportRefLink",
}

/**
 * The initial App state that will be loaded into the AppProvider context.
 */
export const INITIAL_STATE: AppState = {
  /**
   * The initial step number (one-based)
   */
  currentStepNum: getCurrentStepNumFromCache() + 1,

  /**
   * The initial step
   */
  currentStep: DAY1_ONBOARDING_STEPS[getCurrentStepNumFromCache()],

  /**
   * All steps that we will be showing on the Day1 on-boarding wizard
   */
  steps: DAY1_ONBOARDING_STEPS.map((step, index) => {
    // In the case where the wizard resumes from a cached state, the action that advances through the PREVIOUS steps
    // never triggers, meaning the `completed` property on each step is not marked as such, so the wizard does not display the checkmark
    // for the previous steps, among other logic being broken. Instead, mark all previous steps as completed.
    if (index < getCurrentStepNumFromCache()) {
      step.completed = true;
      step.hideNext = false;
      step.nextDisabled = false;
    }
    return step;
  }),

  /**
   * The network connection status in between the NH's laptop and the internet
   */
  isNetworkConnected: false,

  /**
   * Number of the required documents that NHs need to submit. Default to 0.
   */
  requiredDocsNum: 0,

  /**
   * Documents that NHs have submitted so far. It only consists of video recordings for the Day1 wizard.
   */
  documents: [],

  /**
   * The verification status of the documents that NHs are required to submit. Default to NOT_STARTED.
   */
  docsVerificationStatus: VerificationStatus.NOT_STARTED,

  /**
   * If Amplify is configured yet.
   */
  amplifyConfigured: false,

  /**
   * The active region that controls which regional endpoint to use
   * for API calls and which Cognito auth config to use
   */
  activeRegion: undefined,

  /**
   * If the chat support flyout is visible
   */
  chatSupportFlyoutVisible: false,

  /**
   * If the Get Support flyout is open
   */
  getSupportFlyoutVisible: false,

  wizardPath: {
    /**
     * The previous wizard path type that users are routed to.
     */
    previous: undefined,

    /**
     * The wizard path type that users are currently on.
     */
    current: undefined,
  },
};
