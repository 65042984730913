import React, { createContext, useMemo, useReducer } from "react";

import { AppStoreReducer } from "./appStoreReducer";
import { Action, AppState, INITIAL_STATE } from "./constants";

/**
 * The App context which contains the global app state and actions for updating the app state. It will be consumed by
 * other React components to update the global app state.
 */
export const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<Action>;
}>({
  state: INITIAL_STATE,
  dispatch: (action: Action) => undefined,
});

/**
 * The StateProvider that wraps the Home component to ensure the children components to consume the global app states
 * from its context.
 *
 * @param children The children React components.
 * @constructor
 */
export const StateProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(AppStoreReducer, INITIAL_STATE);

  /**
   * Use useMemo to memorize the value given to the provider to prevent re-render.
   * https://hswolff.com/blog/how-to-usecontext-with-usereducer/
   */
  const contextValue: { state: AppState; dispatch: React.Dispatch<Action> } =
    useMemo(() => {
      return { state, dispatch };
    }, [state, dispatch]);

  return (
    <AppContext.Provider value={contextValue}> {children} </AppContext.Provider>
  );
};
