import { BLOCKING_ERROR_TYPE } from "@/apis/constants";
import { Logger } from "@/apis/Logger";
import {
  HelpMsgLocaleId,
  RefPageValue,
} from "@/components/GetSupport/ChatWithITSupport/types";
import { Day1EventLabel } from "@/types/snowplow-events";

/**
 * Get the ref_page value that will be included in the IT support link for the given blocking error type on Day1.
 * @param errorType The blocking error type on Day1.
 */
export const getRefPageByError = (
  errorType: BLOCKING_ERROR_TYPE
): RefPageValue => {
  switch (errorType) {
    case BLOCKING_ERROR_TYPE.BeforeDay1:
    case BLOCKING_ERROR_TYPE.NotDay1:
      return "ezo-blocking-error-not-day-1";
    case BLOCKING_ERROR_TYPE.NoAlias:
      return "ezo-blocking-error-200";
    default:
      return "ezo-blocking-error-400";
  }
};

/**
 * Get the help message that will be included in the IT support link by the given ref-page.
 * @param refPage The ref-page value used in the IT support link.
 */
export const getHelpMsgLocaleIdByRefPage = (
  refPage?: RefPageValue
): HelpMsgLocaleId | undefined => {
  if (!refPage) {
    return undefined;
  }

  switch (refPage) {
    case "ezo-biometric-preference-contact-card":
      return "itHelpMsg.contactSupportOnboardThroughChime";
    case "ezo-blocking-error-100":
    case "ezo-blocking-error-200":
    case "ezo-blocking-error-300":
      return "itHelpMsg.day1BlockingIssue";
    case "ezo-blocking-error-400":
    case "ezo-identity-check-full":
    case "ezo-identity-check-full-pri":
    case "ezo-identity-check-full-sec":
    case "ezo-identity-check-full-challenge":
    case "ezo-identity-check-full-challenge-unable-to-complete-challenge":
    case "ezo-identity-check-full-wait":
    case "ezo-identity-check-lightweight-wait":
      return "itHelpMsg.verificationIssueAndAssist";
    case "ezo-identity-check-full-pass":
    case "ezo-identity-check-lightweight":
      return "itHelpMsg.verificationIssue";
    case "ezo-identity-check-lightweight-pass":
      return "itHelpMsg.settingUpLaptop";
    case "ezo-identity-check-full-fail":
    case "ezo-identity-check-lightweight-challenge-unable-to-complete-challenge":
    case "ezo-identity-check-lightweight-fail":
      return "itHelpMsg.verificationAssist";
    case "ezo-amazon-username":
      return "itHelpMsg.usernameIssue";
    case "ezo-midway-register-sec-key":
      return "itHelpMsg.midwayAssist";
    case "ezo-midway-register-sec-key-after-temp-pin":
      return "itHelpMsg.midwayIssue";
    case "ezo-connect-to-vpn":
      return "itHelpMsg.vpnIssue";
    case "ezo-create-amazon-password":
      return "itHelpMsg.passwordIssue";
    case "ezo-install-precache":
      return "itHelpMsg.preCacheADIssue";
    default:
      void Logger.warn(
        `Cannot get help message locale ID by reference. Invalid ref-page. RefPage: ${refPage}`
      );
      return undefined;
  }
};

/**
 * Get the Snowplow event label for the given blocking error type on Day1.
 * @param errorType The blocking error type on Day1.
 */
export const getMetricsLabelByError = (
  errorType: BLOCKING_ERROR_TYPE
): Day1EventLabel => {
  switch (errorType) {
    case BLOCKING_ERROR_TYPE.BeforeDay1:
    case BLOCKING_ERROR_TYPE.NotDay1:
      return Day1EventLabel.NotDay1;
    case BLOCKING_ERROR_TYPE.NoAlias:
      return Day1EventLabel.AccountIssue;
    default:
      return Day1EventLabel.GenericError;
  }
};
