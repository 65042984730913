import axios from "axios";

import { RETRY_LIMIT, TRANSIENT_ERROR_CODES } from "@/apis/constants";

/**
 * Only retry on Axios errors with a transient error code if the failure count is within the retry limit.
 * @param failureCount The number of failure attempts
 * @param err An Error instance
 */
export const shouldRetry = (failureCount: number, err: unknown): boolean => {
  if (axios.isAxiosError(err) && err.response && failureCount < RETRY_LIMIT) {
    return TRANSIENT_ERROR_CODES.includes(err.response.status);
  } else {
    return false;
  }
};

/**
 * Returns the interval(ms) in between each retry. Starts with 1s and gradually increases as the number of retries
 * increases up to 15 seconds - [1_000, 2_000, 4_000, ..., 15_000].
 * @param retryCount the number of retries.
 */
export const getRetryDelayMs = (retryCount: number): number => {
  return Math.min(500 * 2 ** retryCount, 15_000);
};

export const getRetryDelayWithMax = (
  retryCount: number,
  maxDelay = 15_000
): number => {
  return Math.min(retryCount > 1 ? 2 ** retryCount * 500 : 500, maxDelay);
};
