import { CognitoUser } from "@aws-amplify/auth";
import { BrowserTracker, newTracker } from "@snowplow/browser-tracker";

import { Logger } from "@/apis/Logger";
import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventLabel,
} from "@/types/snowplow-events";

import { getStageConfig } from "../stageConfig";

/**
 * Cognito user identity type
 */
export type CognitoUserIdentity = {
  userId: string;
  providerName: string;
  providerType: string;
  issuer: string | null;
  primary: boolean;
  dateCreated: number;
};

export type MetricsEvent = {
  category: Day1EventCategory;
  action: Day1EventAction;
  label?: Day1EventLabel;
  value?: number;
  property?: string;
};

export const COGNITO_IDENTITY_ATTR_NAME = "identities";

export class SnowplowTracker {
  private static tracker: BrowserTracker | null;
  private static personId: string | undefined;

  /**
   * Initialize the metrics framework.
   * See: https://code.amazon.com/packages/NodeJS-OmniaSnowPlowTracker/trees/mainline
   * See: https://github.com/snowplow/snowplow/wiki/1-General-parameters-for-the-Javascript-tracker#22-initialising-a-tracker
   */
  public static get instance(): BrowserTracker {
    if (!this.tracker) {
      this.tracker = this.getSnowplowTracker();
      try {
        this.tracker.core.setUseragent(navigator.userAgent);
        this.tracker.core.setLang(navigator.language);
        this.tracker.core.setTimezone(
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      } catch (error: unknown) {
        void Logger.errorErrorLike("Unable to set browser contexts", error);
      }
      return this.tracker;
    }

    return this.tracker;
  }

  /**
   * Return the personId of the current authenticated user which is Cognito userId.
   */
  public static get userId(): string | undefined {
    return this.personId;
  }

  /**
   * Configure the userId for the current user journey in our Snowplow and Logger instances.
   * @param user A Cognito user.
   * @param userId The userId string.
   * @param onSuccessCallback Callback to call immediately after the user ID is set.
   *
   * Note on onSuccessCallback:
   * This is implemented because of an async race condition wherein we need to do something like submit a metric
   * enhanced by a user ID immediately when the user ID is available. In this example:
   * ```
   *  Auth.currentAuthenticatedUser()
   *    .then((user: CognitoUser) => {
   *      SnowplowTracker.setUserId(user)
   *      trackStructEvent(...)
   *    })...
   *
   * This is executed async so setUserId never finishes before trackStructEvent is called, causing the resulting event
   * to not have a user ID associated. Instead, use synchronous execution here wherein we guarantee Snowplow has set the
   * user ID and then does something afterwards.
   */
  public static setUserId({
    user,
    userId,
    onSuccessCallback,
  }: {
    user?: CognitoUser;
    userId?: string;
    onSuccessCallback?: () => void;
  }): void {
    if (userId) {
      this.personId = userId;
      SnowplowTracker.instance.setUserId(userId);
      Logger.setUserSessionId(userId);
      onSuccessCallback?.();
    } else if (user) {
      user.getUserAttributes((err, attributes) => {
        const identities = attributes?.find(
          (attribute) => attribute.getName() === COGNITO_IDENTITY_ATTR_NAME
        );

        if (identities) {
          const identitiesAttr = JSON.parse(
            identities.getValue()
          ) as CognitoUserIdentity[];

          if (identitiesAttr.length > 0) {
            const cognitoUserId = identitiesAttr[0].userId;
            this.personId = cognitoUserId;
            SnowplowTracker.instance.setUserId(cognitoUserId);

            // Ensure logger has the user's ID the first time it's available.
            Logger.setUserSessionId(cognitoUserId);
            onSuccessCallback?.();
          } else {
            void Logger.warn(
              "Unable to find the Cognito identity attribute while trying to extract User ID."
            );
          }
        } else {
          void Logger.warn("Unable to find the identities attributes");
        }
      });
    }
  }

  private static getSnowplowTracker(): BrowserTracker {
    return newTracker("d1w", getStageConfig().snowplowEndpoint, {
      appId: getStageConfig().snowplowAppId,
      contexts: {
        webPage: true,
      },
      platform: "web",
      /**
       *  Send the events immediately when they are created to prevent events lost. Consider increasing the value when
       *  we start capturing more events.
       */
      bufferSize: 1,
      postPath: "/prod",
      // https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#link-click-tracking
      plugins: [],
    });
  }
}
