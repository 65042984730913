import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { trackStructEvent } from "@snowplow/browser-tracker";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import { ChatWithITSupportFlyoutLinkProps } from "@/components/GetSupport/ChatWithITSupport/types";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";

/**
 * Creates an inline link which controls the visibility of the chat support flyout on click.
 * @param localeId The i18n ID to render using intl.
 * @param metricProps Optional metrics properties to use on click.
 */
export const ChatWithITSupportFlyoutLink = ({
  localeMessageId,
  metricProps,
}: ChatWithITSupportFlyoutLinkProps): JSX.Element => {
  const { dispatch } = useContext(AppContext);
  const openHandler = () => {
    if (metricProps) {
      // Track metrics on component click
      trackStructEvent({
        category: metricProps.category,
        action: metricProps.action,
        label: metricProps.label,
      });
    }

    dispatch({
      type: ActionType.SET_CHAT_SUPPORT_REF_LINK,
      chatSupportRefLink: "ezo-inline",
    });
    dispatch({
      type: ActionType.SET_CHAT_SUPPORT_FLYOUT_VISIBLE,
      chatSupportFlyoutVisible: true,
    });
  };

  return (
    <>
      <Link
        className="wizard-link"
        textDecoration="none"
        onClick={openHandler}
        dataTestId={"chatWithITSupportFlyoutLink"}
      >
        <Text fontWeight="light" display="inline">
          {<FormattedMessage id={localeMessageId} />}
        </Text>
      </Link>
    </>
  );
};
