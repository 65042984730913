import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@amzn/stencil-react-components/button";
import { trackStructEvent } from "@snowplow/browser-tracker";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { CHAT_SUPPORT_CONTEXTS_METRICS_MAP } from "@/components/GetSupport/ChatWithITSupport/constants";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";

export const GetSupportHeaderLink = (): JSX.Element => {
  const { state, dispatch } = useContext(AppContext);
  const intl = useIntl();
  // Handles clicking on the component.
  const openHandler = () => {
    // If there is non directed support context, track the metric. This should generally be the case but this component
    // is not responsible for setting the support context, so it may not be set yet.
    if (state.nonDirectedChatSupportContext) {
      const { category, action, label } =
        CHAT_SUPPORT_CONTEXTS_METRICS_MAP[state.nonDirectedChatSupportContext];
      trackStructEvent({
        category,
        action,
        label,
      });
    }

    dispatch({
      type: ActionType.SET_CHAT_SUPPORT_REF_LINK,
      chatSupportRefLink: "ezo-chat",
    });

    // Set flyout visibility
    dispatch({
      type: ActionType.SET_GET_SUPPORT_FLYOUT_VISIBLE,
      getSupportFlyoutVisible: true,
    });
  };

  return (
    <Button
      dataTestId={"getSupportHeaderLink"}
      onClick={openHandler}
      variant={ButtonVariant.Secondary}
      size={ButtonSize.Small}
      aria-label={intl.formatMessage({
        id: "arialabels.clickToOpenGetSupport",
      })}
    >
      <FormattedMessage id="getSupport.header" />
    </Button>
  );
};
