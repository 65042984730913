import { activeStepClassName, disabledStepClassName } from "../constants";

/**
 * Return the class name that will be used for the div of each onboarding step based on the current step and status
 * of the current step.
 *
 * @param hasPrevStepCompleted True if the user has already completed the previous step. Otherwise, false.
 */
export default function getSectionDisplayClass(
  hasPrevStepCompleted: boolean
): string {
  return hasPrevStepCompleted ? activeStepClassName : disabledStepClassName;
}
