import { ChatWidgetInitializationParameters } from "@/components/GetSupport/ChatWidget/types/chat-widget";
import { STAGE } from "@/helpers/stageConfig";

/*
 Implements the body for StartChatContact Connect API method.
 If the chat widget loads and says there was an internal error, you probably have something below wrong.

 @see https://code.amazon.com/packages/ITSupportConnectApiModel/blobs/793971fc511ca0152ee31d5ebec01d4c598f8691/--/open-api-definition.yml#L19,L20,L29
 @see https://code.amazon.com/packages/ITSupportConnectCustomerClient/blobs/0d9c1717b6caf283bb6cc73c5dde94c9947cc012/--/src/models/widget-data.ts#L14-L53
*/
export const CHAT_WIDGET_DEFAULT_INITIALIZATION_PARAMETERS: Readonly<ChatWidgetInitializationParameters> =
  {
    midwayAuth: false,
    username: "newhire-day1",
    stage: STAGE === "PROD" ? "prod" : "beta",
    chatFeedbackWidgetSettings: {
      agent: {},
      chatbot: {},
    },
    channel: "chat",
    disableHeader: true,
    displayAgentBadgePhoto: false,
    displayName: "EZO User",
    // https://code.amazon.com/packages/ITSupportConnectApiModel/blobs/793971fc511ca0152ee31d5ebec01d4c598f8691/--/open-api-definition.yml#L997
    language: "en",
    profile: "its-eng",
    reasonForContact: "I'm having a problem using Easy Onboarding Wizard.",
    // Must be: https://code.amazon.com/packages/ITSupportConnectApiModel/blobs/793971fc511ca0152ee31d5ebec01d4c598f8691/--/open-api-definition.yml#L1098
    sourceApp: "EZO",
    refLink: "ezo-chat",
  };
