/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
import KatalMetricsDriverConsoleLogJson from "@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson";
import KatalMetricsContext from "@amzn/katal-metrics/lib/KatalMetricsContext";
import KatalMetricsPublisher from "@amzn/katal-metrics/lib/KatalMetricsPublisher";
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";

import { Logger } from "@/apis/Logger";
import {
  DEFAULT_STAGE_CONFIG,
  getStageConfig,
  STAGE,
} from "@/helpers/stageConfig";

type MetricsInstance = {
  publisherInstance: KatalMetricsPublisher;
};

interface publishQueryMetricsBaseParams {
  metricsPublisherHandler: KatalMetricsPublisher;
  queryFunctionName: string;
}

interface publishQueryResultMetricsParams
  extends publishQueryMetricsBaseParams {
  numSuccess: number;
  numFailures: number;
}

interface publishQueryLatencyMetricsParams
  extends publishQueryMetricsBaseParams {
  elapsedTime: number;
}

/**
 * Error handler callback when attempts to publish metrics fail.
 * @param error The error from publishing metrics.
 */
export const errorHandler = (error: Error): void => {
  void Logger.error("Metrics collection error caught", error, {});
};

/**
 * Sushi Driver to publish the metrics to Sushi. Use it when you want to view your metrics on the Amazon Monitor
 * Portal
 */
const sushiDriver = new KatalMetricsDriverSushi.Builder()
  .withDomainRealm("prod", "USAmazon")
  .withErrorHandler(errorHandler)
  .build();

/**
 * Katal context which groups the published metrics with the given keys such as the name of the website and service.
 */
const metricsContext = new KatalMetricsContext.Builder()
  .withSite(
    getStageConfig()
      ? getStageConfig().snowplowAppId
      : DEFAULT_STAGE_CONFIG.snowplowAppId
  )
  .withServiceName("DayOneWizardWebsite")
  .build() as KatalMetricsContext;

/**
 * Metrics handler.
 *
 * usage:
 *
 * In the calling method, initialize the publisher handle with a description of what the method is, for example AppInit.
 * const metricsPublisherHandler = Metrics.publisherInstance.newChildActionPublisherForMethod("AppInit");
 *
 * To publish to PMET, use either
 * metricsPublisherHandler.publishCounterMonitor("identifierString", 1); or
 * metricsPublisherHandler.publishTimerMonitor("timerString", 100);
 *
 * See: https://katal.amazon.dev/for-developers/metrics/publishing/
 *
 * To view the metrics, make sure that you are using a sushi driver to publish your metrics to the right
 * Katal endpoint. The metrics can be accessed from the Amazon monitor portal with the link:
 * https://monitorportal.amazon.com/igraph?search=<snowplowAppId>
 *
 * Ex: Beta - https://monitorportal.amazon.com/igraph?search=ezo-day1-prelogin-beta
 */
export const Metrics: MetricsInstance = {
  publisherInstance: new KatalMetricsPublisher(
    STAGE === "DEV" ? new KatalMetricsDriverConsoleLogJson() : sushiDriver,
    errorHandler,
    metricsContext
  ),
};

/**
 * Publish the number of success and failed alias fetches through the Katal logger.
 * @param metricsPublisherHandler The Katal publisher to publish the metrics.
 * @param queryFunctionName The name of the query function.
 * @param numSuccess The number of success Amazon alias fetches.
 * @param numFailures The number of failure Amazon alias fetches.
 */
export const publishQueryResultMetrics = ({
  metricsPublisherHandler,
  queryFunctionName,
  numSuccess = 0,
  numFailures = 0,
}: publishQueryResultMetricsParams): void => {
  metricsPublisherHandler.publishCounterMonitor(
    `${queryFunctionName}Success`,
    numSuccess
  );
  metricsPublisherHandler.publishCounterMonitor(
    `${queryFunctionName}Failure`,
    numFailures
  );
};

/**
 * Publish the latency(ms) of an API through the Katal logger.
 * @param metricsPublisherHandler The Katal publisher to publish the metrics.
 * @param queryFunctionName The name of the query function.
 * @param elapsedTime The time spent in fetching/updating data from an API.
 */
export const publishQueryLatencyMetrics = ({
  metricsPublisherHandler,
  queryFunctionName,
  elapsedTime,
}: publishQueryLatencyMetricsParams): void => {
  metricsPublisherHandler.publishTimerMonitor(
    `${queryFunctionName}LatencyMs`,
    elapsedTime
  );
};
