import "./style.scss";

import { Expander } from "@amzn/stencil-react-components/expander";
import { Hr, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { trackStructEvent } from "@snowplow/browser-tracker";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import NextButton from "@/components/Commons/NextButton";
import { Day1EventAction } from "@/types/snowplow-events";

import { ActionType, AppContext } from "../../../stores";
import { day1StepsGridViewPadding, STEP_ID } from "../../constants";
import getSectionDisplayClass from "../onboarding-steps-helper";
import { getStepHeader } from "../StepStatus/step-status";
interface StepProps extends PropsWithChildren<unknown> {
  /**
   * Step ID assigned to each step.
   */
  stepId: STEP_ID;

  /**
   * The additional next handler that needs to be invoked besides setting the step as completed and increment
   * the current step number.
   */
  handleNext?: (...args: unknown[]) => void;

  /**
   * The data test id that will be assigned to the View of the step.
   */
  dataTestId?: string;
}

/**
 * Step container which holds the logic of advancing to the next step and collapses the expander after clicking the
 * next button. It will be used by all onBoarding steps on the Day1 wizard.
 * @param props A StepProps interface
 * @constructor
 */
export default function StepContainer(props: StepProps): JSX.Element | null {
  const intl = useIntl();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { state, dispatch } = useContext(AppContext);
  const targetStepIdx: number = state.steps.findIndex(
    (step) => step.id === props.stepId
  );
  const targetStep = state.steps[targetStepIdx];

  /**
   * Set the target step to completed and collapses the expander if the target step exists in the global app state.
   */
  useEffect(() => {
    if (targetStep) {
      const isExpanded = state.currentStepNum === targetStepIdx + 1;
      setIsExpanded(isExpanded);
      setIsCompleted(targetStep.completed);

      if (isExpanded) {
        /**
         * Submits journey metrics for "section is opened". This should only be triggered for some
         *  sections toggled open, so structuredEventData can be undefined and no metrics are submitted in that case.
         */
        targetStep.eventCategory &&
          trackStructEvent({
            category: targetStep.eventCategory,
            action: Day1EventAction.SectionToggled,
          });

        targetStep.nonDirectedChatSupportContext &&
          dispatch({
            type: ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT,
            nonDirectedChatSupportContext:
              targetStep.nonDirectedChatSupportContext,
          });
      }
    }
  }, [targetStep, state.currentStepNum, targetStepIdx]);

  /**
   * Render the step header with the title and its step number.
   * @param titleText The title of the step.
   * @param toggle The toggle function that will be used to expand/collapse the expander by clicking the header.
   */
  const renderStepHeader = ({
    titleText,
    toggle,
  }: {
    titleText?: string;
    toggle: () => void;
  }) => {
    return getStepHeader(
      targetStepIdx,
      isCompleted,
      isExpanded,
      titleText,
      toggle
    );
  };

  /**
   * Submits Snowplow metrics to be used when the Next button is clicked.
   */
  const createContinueJourneyMetrics = () => {
    /**
     * This metric will be submitted to Snowplow when the Next button is clicked **AND** DAY1_ONBOARDING_STEPS[0].continueByVerificationStatusMetrics
     * is mapped to a particular document verification status. For example, to submit a NEXT button click for a successful Auto IDV, given this configuration in
     * DAY1_ONBOARDING_STEPS[0].continueByVerificationStatusMetrics
     *
     * {
     *         verificationStatus: VerificationStatus.AUTOMATED_IDV_SUCCESS,
     *         se: {
     *           category: Day1EventCategory.AutomatedRivOutcome,
     *           action: Day1EventAction.Continue,
     *           label: Day1EventLabel.ResultPass,
     *         },
     * }
     *
     * The below line searches for metrics associated to the current docsVerificationStatus and then submits them to Snowplow.
     */
    const journeyMetricsFromVerificationStatus =
      targetStep.continueByVerificationStatusMetrics?.find(
        (m) => m.verificationStatus === state.docsVerificationStatus
      );

    journeyMetricsFromVerificationStatus &&
      trackStructEvent({ ...journeyMetricsFromVerificationStatus.se });

    targetStep.eventCategory &&
      trackStructEvent({
        category: targetStep.eventCategory,
        action: Day1EventAction.Continue,
      });
  };

  /**
   * Set the isCompleted boolean flag to true and increment the current active step by 1.
   */
  const handleNext = (): void => {
    createContinueJourneyMetrics();
    setIsCompleted(true);
    setIsExpanded(false);
    if (targetStep) {
      dispatch({
        type: ActionType.NEXT_STEP,
        stepId: targetStep.id,
      });
      props.handleNext?.();
    }
  };

  /**
   * Check whether if the completed steps indices contains the previous index of the selected step.
   */
  const hasPrevStepCompleted = (): boolean => {
    return state.currentStepNum > targetStepIdx || targetStepIdx === 0;
  };

  if (targetStep) {
    return (
      <div
        className={getSectionDisplayClass(hasPrevStepCompleted())}
        data-test-id="stepContainerTestId"
      >
        <View padding={day1StepsGridViewPadding} dataTestId={props.dataTestId}>
          <Expander
            renderTitle={renderStepHeader}
            titleText={intl.formatMessage({ id: targetStep.titleId })}
            isExpanded={targetStep.expandable && isExpanded}
            dataTestId="expanderDataTestId"
          >
            <Hr />
            <Spacer height={15} />
            {props.children}
            <Spacer height="S400" />
            {!targetStep.hideNext && (
              <Row justifyContent="flex-end" className="wizard-next-btn-row">
                <NextButton
                  buttonTextI18nId={targetStep.nextButtonTitleId}
                  dataTestId={`${props.stepId}NextBtn`}
                  onClick={handleNext}
                  disabled={targetStep.nextDisabled}
                  disabledTextI18nId={targetStep.nextButtonDisabledI18nId}
                />
              </Row>
            )}
          </Expander>
        </View>
      </div>
    );
  } else {
    return null;
  }
}
