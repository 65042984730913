import { GetUserAliasResponse400 } from "@amzn/client-workbench-api-model";
import { AxiosError } from "axios";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";

import {
  FETCH_AMAZON_ALIAS_QUERY_KEY,
  fetchAmazonAlias,
} from "@/apis/AmazonUsernameHelper";
import { getRetryDelayMs, shouldRetry } from "@/helpers/react-query-helper";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";

/**
 * Custom hook to fetch the Amazon user alias and set it in the global app state.
 */
export const useAmazonAlias = function (): UseQueryResult<
  string,
  AxiosError | Error
> {
  const { state, dispatch } = useContext(AppContext);
  return useQuery(FETCH_AMAZON_ALIAS_QUERY_KEY, fetchAmazonAlias, {
    onSuccess: (username: string) => {
      dispatch({
        type: ActionType.SET_USERNAME,
        amazonUsername: username,
      });
    },
    retry: (failureCount, err) => shouldRetry(failureCount, err),
    retryDelay: (retryCount) => getRetryDelayMs(retryCount),
    // Run the query only if amplify has been configured
    enabled: state.amplifyConfigured,
  });
};
