import { Text } from "@amzn/stencil-react-components/text";
import { PropsWithChildren } from "react";

/**
 * Wrapper for applying style for step body. Wrap another <Text /> element in this class and the inner <Text /> will inherit the property in this component.
 * @param props React props, used for passing children.
 * @constructor
 */
export const StepBodyStyledText = (
  props: PropsWithChildren<object>
): JSX.Element => {
  return <Text color="neutral90">{props.children}</Text>;
};
