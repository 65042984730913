const DEV_HOSTNAME = "dev.cwb.it.a2z.com";
const BETA_HOSTNAME = "onboarding.beta.cwb.it.a2z.com";
const GAMMA_HOSTNAME = "onboarding.gamma.cwb.it.a2z.com";
const PROD_HOSTNAME = "onboarding.cwb.it.a2z.com";

export type STAGES = "DEV" | "BETA" | "GAMMA" | "PROD";

const HOSTNAME_STAGES: Record<string, STAGES> = {
  [DEV_HOSTNAME]: "DEV",
  [BETA_HOSTNAME]: "BETA",
  [GAMMA_HOSTNAME]: "GAMMA",
  [PROD_HOSTNAME]: "PROD",
};

export const STAGE: STAGES = HOSTNAME_STAGES[window.location.hostname] ?? "DEV";

export interface IStageConfig {
  snowplowEndpoint: string;
  snowplowAppId: string;
  katalLoggerEndpoint: string;
  publicAssetsCfnDomain: string;
  chatWidgetClientSrc: string;
  chatWidgetFeedbackSrc: string;
  ivvCallbackUrl: string;
}

const STAGE_CONFIG: Record<STAGES, IStageConfig> = {
  DEV: {
    snowplowEndpoint: "bzb2snmm53.execute-api.us-west-2.amazonaws.com",
    snowplowAppId: "ezo-day1-prelogin-dev",
    katalLoggerEndpoint:
      "https://odm6lpzzn4.execute-api.us-west-2.amazonaws.com/prod/v1/log",
    publicAssetsCfnDomain: "https://dvg9dqdbdlaxw.cloudfront.net",
    chatWidgetClientSrc: "https://beta.connect.it.a2z.com/client.js",
    chatWidgetFeedbackSrc:
      "https://beta.feedback-widgets.it.a2z.com/git-feedback-widgets-1.0.bundled.js",
    ivvCallbackUrl:
      "aHR0cHM6Ly9kZXYuY3diLml0LmEyei5jb206ODAwMC8jaWR2LWNvbXBsZXRl",
  },
  BETA: {
    snowplowEndpoint: "bzb2snmm53.execute-api.us-west-2.amazonaws.com",
    snowplowAppId: "ezo-day1-prelogin-beta",
    katalLoggerEndpoint:
      "https://odm6lpzzn4.execute-api.us-west-2.amazonaws.com/prod/v1/log",
    publicAssetsCfnDomain: "https://dvg9dqdbdlaxw.cloudfront.net",
    chatWidgetClientSrc: "https://beta.connect.it.a2z.com/client.js",
    chatWidgetFeedbackSrc:
      "https://beta.feedback-widgets.it.a2z.com/git-feedback-widgets-1.0.bundled.js",
    ivvCallbackUrl:
      "aHR0cHM6Ly9vbmJvYXJkaW5nLmJldGEuY3diLml0LmEyei5jb20vI2lkdi1jb21wbGV0ZQ==",
  },
  GAMMA: {
    // Using the beta endpoint since there is no Snowplow storage in Gamma
    snowplowEndpoint: "bzb2snmm53.execute-api.us-west-2.amazonaws.com",
    snowplowAppId: "ezo-day1-prelogin-gamma",
    katalLoggerEndpoint:
      "https://4vn34y4vbi.execute-api.us-west-2.amazonaws.com/prod/v1/log",
    publicAssetsCfnDomain: "https://d36wygoucrr0f9.cloudfront.net",
    chatWidgetClientSrc: "https://connect.it.a2z.com/client.js",
    chatWidgetFeedbackSrc:
      "https://feedback-widgets.it.a2z.com/git-feedback-widgets-1.0.bundled.js",
    ivvCallbackUrl:
      "aHR0cHM6Ly9vbmJvYXJkaW5nLmdhbW1hLmN3Yi5pdC5hMnouY29tLyNpZHYtY29tcGxldGU=",
  },
  PROD: {
    snowplowEndpoint: "sdihol5j2j.execute-api.us-west-2.amazonaws.com",
    snowplowAppId: "ezo-day1-prelogin",
    katalLoggerEndpoint:
      "https://19aygzdn2a.execute-api.us-west-2.amazonaws.com/prod/v1/log",
    publicAssetsCfnDomain: "https://d2i8f0brittkjm.cloudfront.net",
    chatWidgetClientSrc: "https://connect.it.a2z.com/client.js",
    chatWidgetFeedbackSrc:
      "https://feedback-widgets.it.a2z.com/git-feedback-widgets-1.0.bundled.js",
    ivvCallbackUrl:
      "aHR0cHM6Ly9vbmJvYXJkaW5nLmN3Yi5pdC5hMnouY29tLyNpZHYtY29tcGxldGU=",
  },
};

export const DEFAULT_STAGE_CONFIG = STAGE_CONFIG["BETA"];

export const getStageConfig = (): IStageConfig => {
  return STAGE_CONFIG[STAGE];
};
