import {
  DayOneVerificationResponseSchema,
  GetUserProgressResponse,
  VerificationRequestSchema,
  VerificationType,
} from "@amzn/client-workbench-api-model";
import { VideoRecording } from "@amzn/itsvideo-recorder";
import { API } from "@aws-amplify/api";
import axios, { AxiosResponse } from "axios";

import { API_METRICS_HANDLE, CLIENT_APP } from "@/apis/constants";
/**
 * Import the module to itself to allow us mocking it during the test.
 * https://stackoverflow.com/questions/55187438/jest-mock-function-inside-function
 */
import * as IdentityCheckHelper from "@/apis/IdentityCheckHelper";
import { Logger } from "@/apis/Logger";
import {
  publishQueryLatencyMetrics,
  publishQueryResultMetrics,
} from "@/apis/Metrics";

export interface S3DocUploadRequest {
  preSignedUrl: string;
  videoRecording: VideoRecording;
  success: boolean;
}

export enum VerificationStatus {
  /**
   * NHs have not started the process of uploading documents to prove their identities.
   */
  NOT_STARTED = "NotStarted",

  /**
   * NHs have already completed all required steps to verify their IDs.
   */
  COMPLETED = "Completed",

  /**
   * NHs are either ineligible for using RIV services or the documents that they previously uploaded had been rejected.
   */
  CONTACT_ITS = "ContactITS",

  /**
   * NHs had verified their primary and secondary IDs previously. Show lightweight RIV experience.
   */
  LIGHT = "Light",

  /**
   * Documents from the LRIV experience that were submitted by the NH have been approved.
   */
  LIGHT_SUCCESS = "LightSuccess",

  /**
   * NHs had submitted all required documents for LRIV needed for prove their identities but they are pending for
   * ITSE to review.
   */
  LIGHT_PENDING = "LightPending",

  /**
   * Documents from the LRIV experience that were submitted by the NH have been rejected.
   */
  LIGHT_FAIL = "LightFail",

  /**
   * NHs had either opted out from verifying their IDs during pre-day1 and their documents were not verified in time.
   * SHow full RIV experience.
   */
  FULL = "Full",

  /**
   * Documents from the FRIV experience that were submitted by the NH have been approved.
   */
  FULL_SUCCESS = "FullSuccess",

  /**
   * NHs had submitted all required documents for FRIV needed for prove their identities but they are pending for
   * ITSE to review.
   */
  FULL_PENDING = "FullPending",

  /**
   * Documents from the FRIV experience that were submitted by the NH have been rejected.
   */
  FULL_FAIL = "FullFail",

  /** Support for automated IDV specific states */
  AUTOMATED_IDV_SUCCESS = "AutomatedIdvSuccess",
  AUTOMATED_IDV_REJECTED = "AutomatedIdvRejected",
  AUTOMATED_IDV_INFLIGHT = "AutomatedIdvInflight",
}

export const CREATE_VERIFICATION_CACHE_KEY = "createVerification";
export const PRE_SIGNED_URLS_TTL_CACHE_KEY = "preSignedUrlsTtl";
export const USER_PROGRESS_QUERY_NAME = "FetchUserProgress";
export const START_VERIFICATION_QUERY_NAME = "StartVerification";
export const SUBMIT_DOCS_METRIC_NAME = "UploadDocuments";
export const UPLOAD_DOC_TO_S3_METRIC_NAME = "UploadDocToS3";

/**
 * Retrieve the expiration time from the localStorage cache.
 */
export const getPreSignedUrlsTtlFromCache = (): number | undefined => {
  const item = localStorage.getItem(PRE_SIGNED_URLS_TTL_CACHE_KEY);
  // The cached item should be a number but getItem always returns a string. Check if the item can be cast to a number, then cast to number and return.
  // Else, the cached item is not what this method is expecting it to be. Log it and return undefined so the cached item won't get used in that case.
  if (!item) {
    return undefined;
  }

  if (!isNaN(Number(item))) {
    return Number(item);
  }

  void Logger.warn(
    "Error getting cached presigned URL TTL; item in cache is not a valid format.",
    undefined,
    { itemFromCache: item }
  );
  return undefined;
};

/**
 * Store the S3 pre-signed and gesture items in the localStorage cache since CWB API does not support re-requesting for
 * generated S3 pre-signed urls and gesture items. It acts short term solution to solve the scenarios where we lost the
 * pre-signed urls and gesture items from local memory store.
 *
 * @param ttlMs The expiration time of the S3 pre-signed urls and gesture items in milliseconds
 */
export const cachePreSignedUrlsTtl = (ttlMs: number) => {
  // localStorage only supports string storage. This will be converted back into a number in the associated `get` method.
  localStorage.setItem(PRE_SIGNED_URLS_TTL_CACHE_KEY, String(ttlMs));
};

/**
 * Check if the cached S3 pre-signed urls and gesture items are expired.
 */
export const isCachedPreSignedUrlsTtlExpired = (): boolean => {
  const currentTime = new Date().getTime();
  const ttlMs: number | undefined = getPreSignedUrlsTtlFromCache();

  return ttlMs ? ttlMs < currentTime : false;
};

export function parseJSONStringAs<T>(data: string): T | undefined {
  try {
    if (data) {
      return JSON.parse(data) as T;
    } else {
      return undefined;
    }
  } catch (err: unknown) {
    if (err instanceof SyntaxError) {
      void Logger.warn("Failed to parse data due to malformed string", err, {
        dataAsString: data,
      });
      throw err;
    }
    void Logger.warn("Failed to parse data", undefined, {
      dataAsString: data,
    });
    throw err;
  }
}

/**
 * Fetch the S3 pre-signed urls and gesture items from the cache. If the data is not found in cache, retrieve the data
 * through the CWB API and store it in cache.
 *
 * @param verificationType The Verification type such as "ON_BOARDING_LIGHT_VERIFICATION" | "ON_BOARDING_FULL_VERIFICATION"
 * @param signal The abort controller signal.
 * @param primaryId The primaryId type selected by the NH
 */
export const createVerification = async (
  verificationType: VerificationType,
  signal: AbortSignal,
  primaryId?: VerificationRequestSchema["primaryIdFormType"]
): Promise<DayOneVerificationResponseSchema> => {
  // Get the API response from cache.
  const responseDataFromCache: string | null = localStorage.getItem(
    CREATE_VERIFICATION_CACHE_KEY
  );

  // If there was a cached response, parse it and return it.
  if (responseDataFromCache) {
    const cacheData = parseJSONStringAs<DayOneVerificationResponseSchema>(
      responseDataFromCache
    );

    const isCachedDataExpired =
      IdentityCheckHelper.isCachedPreSignedUrlsTtlExpired();

    if (!isCachedDataExpired && cacheData) {
      // Return the data from the cache instead of calling the CWB API again.
      return cacheData;
    }
  }

  const body: VerificationRequestSchema = {
    verificationType: verificationType,
    clientApp: CLIENT_APP,
    primaryIdFormType: primaryId,
  };

  const startTime = performance.now();
  try {
    // Fetch the S3 pre-signed urls and gesture challenges
    const verificationPromise = API.post("API", "/user/verification", {
      body: body,
    }) as Promise<DayOneVerificationResponseSchema>;

    // Listen for abort event on signal to cancel the fetch pre-signed urls request
    signal.addEventListener("abort", () => {
      API.cancel(verificationPromise);
    });

    const response: DayOneVerificationResponseSchema =
      await verificationPromise;

    /*
     Storing into cache to reuse the data later. Note: This is stringified, you must objectify if again later.

     NOTE FOR DEVELOPMENT: This will set the cache key `createVerification` with the verification URLs. If you are
     developing and have cleared the TTL cache key, you will need to clear this property as well in order to get
     new verification presigned URLs.
    */
    localStorage.setItem(
      CREATE_VERIFICATION_CACHE_KEY,
      JSON.stringify(response)
    );

    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: START_VERIFICATION_QUERY_NAME,
      numFailures: 0,
      numSuccess: 1,
    });
    return response;
  } catch (err) {
    void Logger.errorErrorLike(
      `${START_VERIFICATION_QUERY_NAME} failed API call.`,
      err as Error
    );

    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: START_VERIFICATION_QUERY_NAME,
      numFailures: 1,
      numSuccess: 0,
    });

    return Promise.reject(err);
  } finally {
    publishQueryLatencyMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: START_VERIFICATION_QUERY_NAME,
      elapsedTime: performance.now() - startTime,
    });
  }
};

/**
 * Fetch the S3 pre-signed urls for each video recording type and upload all video recordings at once.
 *
 * TODO: Retry logic is ugly. This can be improved in the future.
 * @param verificationType The verification type that we will use to get the S3 pre-signed urls.
 * @param videoRecordings A list of VideoRecordings.
 * @param signal The abort signal that will be used to cancel the request.
 * @param primaryId The primary ID selected by NH for ID verification
 * @return A list of failure requests to upload the video recordings to the S3 bucket. If we failed to get the
 * S3 pre-signed urls, we will return an empty list.
 */
export const submitVideoRecordings = async (
  verificationType: VerificationType,
  videoRecordings: VideoRecording[],
  signal: AbortSignal,
  primaryId?: VerificationRequestSchema["primaryIdFormType"]
): Promise<S3DocUploadRequest[]> => {
  const startTime = performance.now();
  try {
    // Fetch the S3 pre-signed urls
    const response: DayOneVerificationResponseSchema =
      await IdentityCheckHelper.createVerification(
        verificationType,
        signal,
        primaryId
      );

    // For each document url item, create a promise to upload the corresponding doc to the S3 bucket
    const s3UploadRequests: S3DocUploadRequest[] = videoRecordings.map(
      (recording) => {
        const docUrlItem = response.documentUrlItems.find(
          (docUrlItem) => recording.documentType === docUrlItem.documentType
        );

        // Throw an error if the document url item is not found
        if (!docUrlItem) {
          const errMsg = `Unable to find the pre-signed url for the document: ${recording.documentType}`;
          void Logger.errorErrorLike(errMsg, undefined, {
            documentCount: videoRecordings.length,
            documentType: recording.documentType,
          });
          throw new Error(errMsg);
        }

        return {
          preSignedUrl: docUrlItem.preSignedUrl,
          videoRecording: recording,
          success: false,
        };
      }
    );

    const uploadS3Promises: Promise<AxiosResponse>[] = s3UploadRequests.map(
      (request) =>
        uploadDocToS3(
          request.preSignedUrl,
          request.videoRecording.videoBlob,
          signal
        )
    );

    // Return all documents upload promises
    const recordingsToRetry: S3DocUploadRequest[] = [];
    const results = await Promise.allSettled(uploadS3Promises);
    results.forEach((result, idx) => {
      const resultStatusIsRejected = result.status === "rejected";
      if (resultStatusIsRejected) {
        recordingsToRetry.push(s3UploadRequests[idx]);
      }

      publishQueryResultMetrics({
        metricsPublisherHandler: API_METRICS_HANDLE,
        queryFunctionName: SUBMIT_DOCS_METRIC_NAME,
        numSuccess: resultStatusIsRejected ? 0 : 1,
        numFailures: resultStatusIsRejected ? 1 : 0,
      });
    });

    return recordingsToRetry.length > 0
      ? Promise.reject(recordingsToRetry)
      : Promise.resolve(recordingsToRetry);
  } catch (err) {
    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: SUBMIT_DOCS_METRIC_NAME,
      numSuccess: 0,
      numFailures: 1,
    });
    void Logger.errorErrorLike(
      "Failed to upload documents due to an uncaught exception.",
      err,
      {
        recordingCount: videoRecordings.length,
      }
    );
    // Return an empty array to retry uploading all documents again later
    return Promise.reject([]);
  } finally {
    publishQueryLatencyMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: SUBMIT_DOCS_METRIC_NAME,
      elapsedTime: performance.now() - startTime,
    });
  }
};

/**
 * Upload the video blob to the S3 preSigned url.
 * @param preSignedUrl The S3 preSigned url
 * @param videoBlob The video blob of the recording.
 * @param signal The signal that will be used to cancel the request.
 */
export const uploadDocToS3 = async (
  preSignedUrl: string,
  videoBlob: Blob,
  signal: AbortSignal
): Promise<AxiosResponse> => {
  const startTime = performance.now();
  try {
    const headers: Record<string, string> = {
      "Content-Type": videoBlob.type,
    };
    /**
     * Extract the AWS KMS encryption key ARN from the S3 presigned URL metadata search parameters.
     *
     * @note In SDK v3, the key ID is not added by default to the signed URL, however it must be included when
     * uploading to the signed URL. To make the key ID available to the client, the CWB backend added the KMS
     * encryption key ID to the object metadata. That way, the client can parse it from the `x-amz-meta-kms-key-id`
     * metadata search parameter and include it in the request headers when uploading to the signed URL.
     */
    const KMS_KEY_METADATA_SEARCH_PARAM = "x-amz-meta-kms-key-id";
    const kmsKeyArn = new URL(preSignedUrl).searchParams.get(
      KMS_KEY_METADATA_SEARCH_PARAM
    );
    /**
     * For server-side encryption with SDK v3, the encryption key ID must be included in the headers when
     * uploading to the presigned URL due to an S3 requirement.
     *
     * @see https://aws.amazon.com/blogs/developer/generate-presigned-url-modular-aws-sdk-javascript
     */
    if (kmsKeyArn) {
      headers["x-amz-server-side-encryption"] = "aws:kms";
      headers["x-amz-server-side-encryption-aws-kms-key-id"] = kmsKeyArn;
    }
    const result = await axios.put(preSignedUrl, videoBlob, {
      headers,
      signal,
    });

    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: UPLOAD_DOC_TO_S3_METRIC_NAME,
      numFailures: 0,
      numSuccess: 1,
    });

    return result;
  } catch (error: unknown) {
    publishQueryResultMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: UPLOAD_DOC_TO_S3_METRIC_NAME,
      numFailures: 1,
      numSuccess: 0,
    });
    void Logger.errorErrorLike("Failed to upload document to S3.", error);
    throw error;
  } finally {
    publishQueryLatencyMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: UPLOAD_DOC_TO_S3_METRIC_NAME,
      elapsedTime: performance.now() - startTime,
    });
  }
};

/**
 * Fetch user progress from API and submit latency metrics.
 * TODO: Does not need retry since it refetches in caller method. Does need latency metrics
 */
export const fetchUserProgress = async (): Promise<GetUserProgressResponse> => {
  const startTime = performance.now();
  try {
    const response = (await API.get(
      "API",
      "/user/progress",
      {}
    )) as GetUserProgressResponse;

    publishQueryResultMetrics({
      queryFunctionName: USER_PROGRESS_QUERY_NAME,
      metricsPublisherHandler: API_METRICS_HANDLE,
      numSuccess: 1,
      numFailures: 0,
    });

    return response;
  } catch (err) {
    void Logger.errorErrorLike(
      `${USER_PROGRESS_QUERY_NAME} failed API call.`,
      err
    );
    publishQueryResultMetrics({
      queryFunctionName: USER_PROGRESS_QUERY_NAME,
      metricsPublisherHandler: API_METRICS_HANDLE,
      numSuccess: 0,
      numFailures: 1,
    });
    return Promise.reject(err);
  } finally {
    publishQueryLatencyMetrics({
      metricsPublisherHandler: API_METRICS_HANDLE,
      queryFunctionName: USER_PROGRESS_QUERY_NAME,
      elapsedTime: performance.now() - startTime,
    });
  }
};
