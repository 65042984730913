import {
  IconCheckCircleFill,
  IconSize,
} from "@amzn/stencil-react-components/icons";
import { Col, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { H4 } from "@amzn/stencil-react-components/text";
import * as React from "react";

type StatusBadgeProps = {
  isCompleted: boolean;
  isActive: boolean;
  activeStepIdx: number;
};

export default function StepStatus(props: StatusBadgeProps): JSX.Element {
  if (props.isCompleted) {
    return (
      <View color="green60" dataTestId="iconCheckCircleFill">
        <Spacer height={5} />
        <IconCheckCircleFill
          className="completed-checkmark-status"
          size={IconSize.Large}
          aria-hidden={true}
        />
      </View>
    );
  } else {
    return (
      <View dataTestId="iconCheckCircleFillNotCompleted">
        <Spacer height={5} />
        <button
          className={props.isActive ? "active-step-btn" : "incomplete-step-btn"}
        >
          <span>{props.activeStepIdx + 1}</span>
        </button>
      </View>
    );
  }
}

/**
 * Get step header with the title and step status.
 *
 * @param onboardingStepIdx the onboarding step index assigned to the step.
 * @param isCompleted Is the onboarding step completed
 * @param isActive Is this step the active step? Active steps are highlighted differently.
 * @param titleText The step title
 * @param toggle The toggle function to expand the expander.
 */
export const getStepHeader = (
  onboardingStepIdx: number,
  isCompleted: boolean,
  isActive: boolean,
  titleText?: string,
  toggle?: () => void
): JSX.Element => (
  <div>
    <Col height="S100" />
    <Row columnGap="S400" onClick={toggle}>
      <StepStatus
        activeStepIdx={onboardingStepIdx}
        isCompleted={isCompleted}
        isActive={isActive}
      />
      <H4>{titleText}</H4>
    </Row>
    {/* The spacer causes the contents in inactive expander header rows to center vertically. It causes a weird spacing above the <hr /> in active content, so only add it for inactive header rows. */}
    {!isActive && <Spacer height="S200" />}
  </div>
);
