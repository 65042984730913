import {
  Button,
  ButtonProps,
  ButtonVariant,
} from "@amzn/stencil-react-components/button";
import { withTooltip } from "@amzn/stencil-react-components/tooltip";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface NextStepButtonProps extends ButtonProps {
  buttonTextI18nId: string;
  dataTestId?: string;
}

interface NextButtonWithDisabledProps extends NextStepButtonProps {
  disabledTextI18nId?: string;
}

interface ButtonWithTestAttrProp extends ButtonProps {
  dataTestId?: string;
}

/**
 * The disabled next button that will advance to the next step with the tooltip text.
 */
const DisabledButton = withTooltip<ButtonWithTestAttrProp>({
  useTriggerWrapper: false,
})(Button);

/**
 * The next button that will be rendered in each step.
 */
export default function NextButton({
  disabled,
  onClick,
  dataTestId,
  buttonTextI18nId,
  disabledTextI18nId,
}: NextButtonWithDisabledProps): JSX.Element {
  const intl = useIntl();
  const disabledToolTipText =
    disabled && disabledTextI18nId
      ? intl.formatMessage({
          id: disabledTextI18nId,
        })
      : undefined;

  /** Show the disabled tooltip only if the text is provided */
  if (disabled && disabledToolTipText) {
    return (
      <DisabledButton
        variant={ButtonVariant.Primary}
        tooltipText={disabledToolTipText}
        disabled={disabled}
        dataTestId={dataTestId}
      >
        <FormattedMessage id={buttonTextI18nId} />
      </DisabledButton>
    );
  } else {
    return (
      <Button
        variant={ButtonVariant.Primary}
        onClick={onClick}
        disabled={disabled}
        dataTestId={dataTestId}
      >
        <FormattedMessage id={buttonTextI18nId} />
      </Button>
    );
  }
}
