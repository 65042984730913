export enum OPERATING_SYSTEM {
  MAC = "mac",
  WINDOWS = "windows",
  LINUX = "linux",
}

/**
 * Retrieve the OS of the NH's browser is currently running on.
 */
export const getOS = (): string => {
  const platformString = navigator.userAgent;
  if (platformString.includes("Mac")) return OPERATING_SYSTEM.MAC;
  else if (platformString.includes("Win")) return OPERATING_SYSTEM.WINDOWS;
  else if (platformString.includes("Linux")) return OPERATING_SYSTEM.LINUX;
  return platformString;
};
