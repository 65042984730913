export enum Day1EventCategory {
  AmazonUsername = "AMAZON-USERNAME",
  BlockingError = "BLOCKING-ERROR",
  Complete = "COMPLETE",
  ConnectToVpn = "CONNECT-TO-VPN",
  CreateAmazonPassword = "CREATE-AMAZON-PASSWORD",
  IDCheckFull = "IDENTITY-CHECK-FULL",
  IDCheckFullPri = "IDENTITY-CHECK-FULL-PRI",
  IDCheckFullSec = "IDENTITY-CHECK-FULL-SEC",
  IDCheckFullWait = "IDENTITY-CHECK-FULL-WAIT",
  IDCheckFullChallenge = "IDENTITY-CHECK-FULL-CHALLENGE",
  IDCheckLight = "IDENTITY-CHECK-LIGHTWEIGHT",
  IDCheckLightWait = "IDENTITY-CHECK-LIGHTWEIGHT-WAIT",
  MidwayRegisterSecKey = "MIDWAY-REGISTER-SEC-KEY",
  InstallPrecache = "INSTALL-PRECACHE",
  PageLevel = "PAGE-LEVEL",
  IDVPrefSelect = "IDV-PREF-SELECT",
  RIVAutomatedConsentOptin = "AUTOMATED-RIV-CONSENT-OPTIN",
  AutomatedRivScreen = "AUTOMATED-RIV-SCREEN",
  AutomatedRivOutcome = "AUTOMATED-RIV-OUTCOME",
}

export enum Day1EventAction {
  NotDay1 = "NOT-DAY-1",
  NoStartDate = "NO-START-DATE",
  AccountIssue = "ACCOUNT-ISSUE",
  ChatWithSupport = "CHAT-WITH-SUPPORT",
  SectionToggled = "SECTION-TOGGLED",
  SectionDisplayed = "SECTION-DISPLAYED",
  // When the preference was set in PD1 and selected **for** the user.
  IDVAutoSelectAutomatedUsed = "PREV-SELECT-AUTOMATED",

  // When the preference was set in PD1 and selected **for** the user.
  IDVAutoSelectManualUsed = "PREV-SELECT-MANUAL",

  // When the preference is selected by the user.
  IDVSelectManual = "SELECT-MANUAL",

  // When the preference is selected by the user.
  IDVSelectAutomated = "SELECT-AUTOMATED",

  // When the Contact Support card is selected by the user.
  IDVSelectContactSupport = "SELECT-CONTACT-SUPPORT",

  ClickSubmit = "CLICK-SUBMIT",
  IVVIframeLoaded = "IFRAME-LOADED",
  IVVIframeLoadError = "IFRAME-LOAD-ERROR",
  CompletionRedirect = "COMPLETION-REDIRECT",
  ExpiryRedirect = "EXPIRY-REDIRECT",
  ConsentScrollTop = "CONSENT-SCROLL-TOP",
  ConsentScrollBottom = "CONSENT-SCROLL-BOTTOM",
  CheckOptIn = "CHECK-OPT-IN",
  UncheckOptIn = "UNCHECK-OPT-IN",
  ClickCancel = "CLICK-CANCEL",
  ClickAgree = "CLICK-AGREE",

  ClickRivSection = "CLICK-RIV-SECTION",
  BackToRivList = "BACK-TO-RIV-LIST",
  SelectPrimaryId = "SELECT-PRIMARY-ID",
  Continue = "CONTINUE",
  ExpandGuidance = "EXPAND-GUIDANCE",
  CollapseGuidance = "COLLAPSE-GUIDANCE",
  ChallengeAction1Set = "CHALLENGE-ACTION-1-SET",
  ChallengeAction2Set = "CHALLENGE-ACTION-2-SET",
  DisplayWaitUI = "DISPLAY-WAIT-UI",
  CheckRivStatus = "CHECK-RIV-STATUS",
  ShowRivStatus = "SHOW-RIV-STATUS",
  SendTempPin = "SEND-TEMP-PIN",
  SendTempPinResponse = "SEND-TEMP-PIN-RESPONSE",
  ResendTempPin = "RESEND-TEMP-PIN",
  OpenMidwayPortal = "OPEN-MIDWAY-PORTAL",
  CheckedConfirmation = "CHECKED-CONFIRMATION",
  UncheckedConfirmation = "UNCHECKED-CONFIRMATION",
  CloseMidwayPortalManual = "CLOSE-MIDWAY-PORTAL-MANUALLY",
  ClosePasswordToolManual = "CLOSE-PASSWORD-TOOL-MANUALLY",
  OpenPasswordTool = "OPEN-PASSWORD-TOOL",
  GenericError = "GENERIC-ERROR",
  WizardUnload = "WIZARD-UNLOAD",
  WizardLoad = "WIZARD-LOAD",
  RecordVideoClicked = "RECORD-VIDEO-CLICKED",
}

export enum Day1EventLabel {
  NotDay1 = "NOT-DAY-1",
  NoStartDate = "NO-START-DATE",
  AccountIssue = "ACCOUNT-ISSUE",
  GenericError = "GENERIC-ERROR",
  PrimaryId = "PRIMARY-ID",
  SecondaryId = "SECONDARY-ID",
  ChallengeAction = "CHALLENGE-ACTION",
  IDCheckFullFail = "IDENTITY-CHECK-FULL-FAIL",
  IDCheckLightFail = "IDENTITY-CHECK-LIGHTWEIGHT-FAIL",
  WaitForResult = "WAITING-FOR-RESULTS",
  ResultPass = "RESULT-PASS",
  ResultFail = "RESULT-FAIL",
  ResultExpiry = "RESULT-EXPIRY",
  UnableToCompleteChallenge = "UNABLE-TO-COMPLETE-CHALLENGE",
  OpenChatSupportPanel = "OPEN-CHAT-SUPPORT-PANEL",
  CloseChatSupportPanel = "CLOSE-CHAT-SUPPORT-PANEL",
  StartSupportChat = "START-SUPPORT-CHAT",
  MIDWAY_ERROR_100 = "EZO-MW-ERROR-100",
  MIDWAY_ERROR_200 = "EZO-MW-ERROR-200",
  MIDWAY_ERROR_999 = "EZO-MW-ERROR-999",
  MIDWAY_SUCCESS = "EZO-MW-SUCCESS",
  IDVSelectedAutomated = "SELECT-AUTOMATED",
  IDVSelectedManual = "SELECT-MANUAL",
  OptedIn = "OPTED-IN",
  NotOptedIn = "NOT-OPTED-IN",
  ConsentConfirmedTrue = "CONSENT-CONFIRMED",
  ConsentConfirmedFalse = "CONSENT-NOT-CONFIRMED",
  LocationConfirmationTrue = "LOC-CONFIRMED",
  LocationConfirmationFalse = "LOC-NOT-CONFIRMED",
}

export enum Day1EventProperty {
  Pass = "PASS",
  Fail = "FAIL",
}
