import { Text } from "@amzn/stencil-react-components/text";
import { StencilThemeFont } from "@amzn/stencil-react-theme-default";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";

/**
 * ordered list item with pre-configured style
 */
const ListItem = styled.li`
  line-height: 2;
`;

const List = styled.ol`
  list-style-position: outside;
  padding-left: 15px;
`;

interface ListProps {
  /**
   * A list of i18n ids that maps into messages of ordered list items.
   */
  i18nIds: string[];

  /**
   * The data-test-id that will be used for testing
   */
  dataTestId?: string;

  /**
   * The font size of the text displayed in the ordered list
   */
  fontSize?: keyof StencilThemeFont["size"] | string | number;

  /**
   * The font weight of the text displayed in the ordered list
   */
  fontWeight: keyof StencilThemeFont["fontWeight"] | number;
}

/**
 * An ordered list.
 * @param i18nIds A list of i18n ids that maps into messages of ordered list items.
 * @param dataTestId The data-test-id that will be used for testing
 * @param fontSize The font size of the text displayed in the ordered list
 * @param fontWeight The font weight of the text displayed in the ordered list
 */
export const OrderedList = ({
  i18nIds,
  dataTestId,
  fontSize,
  fontWeight,
}: ListProps): JSX.Element => {
  return (
    <List data-test-id={dataTestId}>
      {i18nIds.map((id) => (
        <Text key={id} fontSize={fontSize} fontWeight={fontWeight}>
          <ListItem>
            <FormattedMessage id={id} />
          </ListItem>
        </Text>
      ))}
    </List>
  );
};

OrderedList.defaultProps = {
  fontSize: "T300",
  fontWeight: "light",
};
