import "../../styles/header.scss";

import { Row, Spacer } from "@amzn/stencil-react-components/layout";
import {
  PageHeader,
  PageHeaderColorScheme,
  PageHeaderList,
} from "@amzn/stencil-react-components/page";
import * as React from "react";

import { GetSupportHeaderLink } from "@/components/GetSupport";

import Day1Logo from "../../assets/icons/day1logo.svg";

/**
 * A fixed header will be displayed on the Day1 onboarding page.
 */
export default function Header(): JSX.Element {
  return (
    <PageHeader
      isFullWidth
      isFixed
      colorScheme={PageHeaderColorScheme.Inverted}
      hasShadow
      dataTestId="ezoHeader"
    >
      <nav id="navbar" aria-label="Site">
        <Row alignItems="center" height="100%" width="100%">
          <img src={Day1Logo} alt="Day 1 Wizard icon" />
          <Spacer flex={1} />
          <PageHeaderList items={[<GetSupportHeaderLink key={1} />]} />
        </Row>
      </nav>
    </PageHeader>
  );
}
