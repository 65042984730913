import { IdvPreference } from "@amzn/client-workbench-api-model";
import { VideoRecording } from "@amzn/itsvideo-recorder";
import { StructuredEvent } from "@snowplow/tracker-core";

import { VerificationStatus } from "@/apis/IdentityCheckHelper";
import { ChatSupportContextReason } from "@/components/GetSupport/ChatWithITSupport/constants";
import { OPERATING_SYSTEM } from "@/helpers/os-helper";
import { WizardPathType } from "@/stores/constants";
import { Image, VideoFileConfig } from "@/types/common";
import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventLabel,
} from "@/types/snowplow-events";

export const activeStepClassName = "active-step";
export const disabledStepClassName = "disabled-step";
export const day1StepsViewClassName = "its-day1-steps-view";
export const day1StepsImageClassName = "step-image";
export const day1StepsGridViewPadding: string[] = ["S200", "S500"];
export const ITS_LIGHT_TEAL = "#F5FAFB";
export type JourneyMetricsVerificationStatusArray = {
  verificationStatus?: VerificationStatus;

  // Represents status of RIV since this can change the journey metrics.
  IVVResult?: "PASS" | "REJECTED" | "EXPIRED";
  se: StructuredEvent;
}[];
export interface VideoRecordingSubmission extends VideoRecording {
  // A flag shows if NHs have clicked the submit button.
  submitClicked: boolean;
}

export interface StepsList {
  /**
   * The locale id of the step. It will be translated into the instruction mapped in the locale file.
   */
  stepLocaleId: string;

  /** Image that will be displayed to guide the NH */
  image?: Image;

  /**
   * Additional components that will be rendered as children within a step.
   */
  children?: JSX.Element;
}

/**
 * The ID that will be assigned to each step.
 */
export enum STEP_ID {
  IDENTITY_CHECK = "IdentityCheck",
  AMAZON_USERNAME = "AmazonUsername",
  REGISTER_SECURITY_KEY = "RegisterSecurityKey",
  CONNECT_VPN = "ConnectVpn",
  AMAZON_PASSWORD = "AmazonPassword",
  PRE_CACHE_AD_MOBILE = "PreCacheADMobile",
}

/**
 * The step interface which contains the state of each step.
 */
export interface Step {
  /**
   * The id assigned to the step.
   */
  id: STEP_ID;

  /**
   * The locale id that is needed to render the title of the expander of the step.
   */
  titleId: string;

  /**
   * The locale id that is needed to render the next button of the step.
   */
  nextButtonTitleId: string;

  /**
   * The i18n id that will be used to map into the disabled tooltip text.
   */
  nextButtonDisabledI18nId?: string;

  /**
   * True if the requirements of the steps are not completed before moving to the next step. Otherwise, return
   * false to allow NHs to advance to the next step.
   */
  nextDisabled?: boolean;

  /**
   * True if the previous step is not completed. Otherwise, return false to mark the step as active.
   */
  disabled?: boolean;

  /**
   * True if the step is completed to render the checkmark icon and collapses the expander. Otherwise, return false
   * to render the step number.
   */
  completed: boolean;

  /**
   * True if NHs are working on the step to expand the step expander. Otherwise, return false to keep the expander
   * collapsed.
   */
  expandable: boolean;

  /**
   * Only show the step if NH is using an operating system from the list if the list is specified.
   */
  platforms?: string[];

  /**
   * Should the next button be hidden.
   */
  hideNext?: boolean;

  /**
   * The reason why NHs contact the ITSE when they first land on the step. We need to track this through Snowplow
   * and update the search params of the IT support chat link accordingly.
   */
  nonDirectedChatSupportContext?: ChatSupportContextReason;

  /**
   * The metrics map used  to provide continue metrics depending on the verification status. This is an edge case for metrics used for RIV metrics.
   */
  continueByVerificationStatusMetrics?: JourneyMetricsVerificationStatusArray;

  /**
   * The event category to use. Used to generate snowplow event metrics for continue and expanded metrics.
   */
  eventCategory?: Day1EventCategory;
}

/**
 * All steps with their state data. It is used to loaded into the context values of the AppProvider.
 */
export const DAY1_ONBOARDING_STEPS: Step[] = [
  {
    id: STEP_ID.IDENTITY_CHECK,
    titleId: "identityCheck.expander.title",
    nextButtonTitleId: "button.next",
    nextDisabled: true,
    hideNext: true,
    completed: false,
    expandable: true,
    continueByVerificationStatusMetrics: [
      {
        verificationStatus: VerificationStatus.FULL_SUCCESS,
        se: {
          category: Day1EventCategory.IDCheckFullWait,
          action: Day1EventAction.Continue,
        },
      },
      {
        verificationStatus: VerificationStatus.LIGHT_SUCCESS,
        se: {
          category: Day1EventCategory.IDCheckLightWait,
          action: Day1EventAction.Continue,
        },
      },
      {
        verificationStatus: VerificationStatus.AUTOMATED_IDV_SUCCESS,
        se: {
          category: Day1EventCategory.AutomatedRivOutcome,
          action: Day1EventAction.Continue,
          label: Day1EventLabel.ResultPass,
        },
      },
      {
        verificationStatus: VerificationStatus.AUTOMATED_IDV_INFLIGHT,
        se: {
          category: Day1EventCategory.AutomatedRivOutcome,
          action: Day1EventAction.SectionToggled,
          label: Day1EventLabel.WaitForResult,
        },
      },
    ],
  },
  {
    id: STEP_ID.AMAZON_USERNAME,
    titleId: "amazonUsername.expander.title",
    nextButtonTitleId: "button.next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_USERNAME_STEP",
    eventCategory: Day1EventCategory.AmazonUsername,
  },
  {
    id: STEP_ID.REGISTER_SECURITY_KEY,
    titleId: "registerSecurityKey.expander.title",
    nextButtonTitleId: "button.next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_MIDWAY_STEP",
    eventCategory: Day1EventCategory.MidwayRegisterSecKey,
  },
  {
    id: STEP_ID.CONNECT_VPN,
    titleId: "connectVpn.expander.title",
    nextButtonTitleId: "button.next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_CONNECT_TO_VPN_STEP",
    eventCategory: Day1EventCategory.ConnectToVpn,
  },
  {
    id: STEP_ID.AMAZON_PASSWORD,
    titleId: "amazonPassword.expander.title",
    nextButtonTitleId: "button.next",
    nextDisabled: true,
    completed: false,
    expandable: true,
    nonDirectedChatSupportContext: "ON_CREATE_PASSWORD_STEP",
    eventCategory: Day1EventCategory.CreateAmazonPassword,
  },
  {
    id: STEP_ID.PRE_CACHE_AD_MOBILE,
    titleId: "preCacheADMobile.expander.title",
    nextButtonTitleId: "button.next",
    nextDisabled: false,
    completed: false,
    expandable: true,
    platforms: [OPERATING_SYSTEM.MAC],
    nonDirectedChatSupportContext: "ON_PRECACHE_STEP",
    eventCategory: Day1EventCategory.InstallPrecache,
  },
];

/**
 * Videos that are used for guiding NHs in different onBoarding steps.
 */
export enum VideoGuidanceName {
  PRIMARY_ID_DESKTOP = "PRIMARY_ID_DESKTOP",
  SECONDARY_ID_DESKTOP = "SECONDARY_ID_DESKTOP",
  CONNECT_TO_VPN_MAC = "CONNECT_TO_VPN_MAC",
  CONNECT_TO_VPN_WINDOWS = "CONNECT_TO_VPN_WINDOWS",
}

/**
 * A config map which contains the videos with their configs such as filename and fileType.
 * The videos will be loaded to guide NHs during their onBoarding processes.
 */
export const VIDEOS_PLAYBACK_CONFIGS: Record<
  VideoGuidanceName,
  VideoFileConfig
> = {
  PRIMARY_ID_DESKTOP: {
    fileNameOrSrcUrl: {
      fileName: "primaryId-desktop-animation.mp4",
    },
    fileType: "video/mp4",
  },
  SECONDARY_ID_DESKTOP: {
    fileNameOrSrcUrl: {
      fileName: "secondaryId-desktop-animation.mp4",
    },
    fileType: "video/mp4",
  },
  CONNECT_TO_VPN_MAC: {
    fileNameOrSrcUrl: {
      fileName: "connect-to-vpn-mac.mp4",
    },
    fileType: "video/mp4",
  },
  CONNECT_TO_VPN_WINDOWS: {
    fileNameOrSrcUrl: {
      fileName: "connect-to-vpn-windows.mp4",
    },
    fileType: "video/mp4",
  },
};

/**
 * A map which contains the mapping of the idv preference to the wizard path type.
 */
export const IDV_PREFERENCE_TO_WIZARD_PATH_TYPE: Record<
  IdvPreference,
  WizardPathType | undefined
> = {
  NONE: undefined,
  AUTOMATED: "IVV",
  MANUAL: "ManualRIV",
};
